import React from 'react'
import { TextField, Typography } from '@mui/material'
import { COLOR } from '../../../constants/color'
const TextFieldCustome = ({
  name,
  id,
  label,
  desc,
  value,
  helperText,
  error,
  onChange,
  disable,
}) => {
  return (
    <React.Fragment>
      <TextField
        color="primary"
        sx={{
          mt: '0.7rem',
          mb: '0.4rem',
        }}
        id={id}
        name={name}
        label={label}
        value={value}
        fullWidth
        onChange={onChange}
        error={error}
        helperText={helperText}
        disabled={disable ?? false}
      />
      {desc && (
        <Typography
          component="div"
          variant="span"
          sx={{
            fontSize: '0.8rem',
            color: COLOR.bg_TEXT_DARK_2,
            fontStyle: 'italic',
          }}
        >
          {desc}
        </Typography>
      )}
    </React.Fragment>
  )
}

export default TextFieldCustome
