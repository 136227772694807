import Earnings from '../pages/admin/earning'
import Email from '../pages/admin/email'
import Events from '../pages/admin/events'
import SetUp from '../pages/admin/set-up'
import Settings from '../pages/admin/settings'
import Students from '../pages/admin/students'
import Surveys from '../pages/admin/surveys'
import Course from '../pages/course'
import Detail_Course from '../pages/detail-course'
import Home from '../pages/home'
import Login from '../pages/login'
import Navigation_Stu_Or_Teacher from '../pages/navigate'
import PayForm from '../pages/payment'
import StudentForm from '../pages/student'
import TeacherForm from '../pages/teacher'

const mainRoutes = [
  {
    component: Home,
    path: '/',
  },
  {
    component: Login,
    path: '/login',
  },
  {
    component: TeacherForm,
    path: '/teacherForm',
  },
  {
    component: StudentForm,
    path: '/studentForm',
  },
  {
    component: Course,
    path: '/mycourse',
  },
  {
    component: Detail_Course,
    path: '/detail-course',
  },
  {
    component: Navigation_Stu_Or_Teacher,
    path: '/navigate-teacher-student',
  },
  {
    component: PayForm,
    path: '/payment-form/:idCourse',
  },
]
const adminRoutes = [
  {
    component: Earnings,
    path: '/admin/earnings',
  },
  {
    component: SetUp,
    path: '/admin/set-up',
  },
  {
    component: Students,
    path: '/admin/students',
  },
  {
    component: Email,
    path: '/admin/email',
  },
  {
    component: Events,
    path: '/admin/events',
  },
  {
    component: Surveys,
    path: '/admin/surveys',
  },
  {
    component: Settings,
    path: '/admin/settings',
  },
]

export { mainRoutes, adminRoutes }
