import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import '../../static/css/teacher.css'
import { COLOR } from '../../constants/color'
import { useFormik } from 'formik'
import { validationSchema } from '../../utils/teacherValidate'
import TextFieldCustome from './components/custome_textfield'
const lstCountries = require('../../dummyData/countries.json')

const TeacherForm = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: '',
      fullname: '',
      coursename: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmitModal(values),
  })
  const [selectCountry, setSelectCountry] = React.useState('Viet Nam')
  const [acceptPrivacy, setAcceptPrivacy] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [touchCheckBox, setTouchCheckBox] = React.useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const handleSubmitModal = (values) => {
    const { email, fullname, coursename } = values

    if (acceptPrivacy) {
      const data = {
        from_name: 'Boost',
        to_name: fullname,
        message:
          'Cảm ơn bạn đã đăng ký trở thành giảng viên tại Boost, chúng tôi rất hân hạnh cùng bạn tạo nên một cộng đồng tươi mới và mang lại nhiều giá trị cho học viện tại Boost.',
        to_email: email,
      }

      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          data,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY,
        )
        .then(
          (result) => {
            setOpen(true)

            setTimeout(() => {
              setOpen(false)
              navigate('/')
            }, 3000)
          },
          (error) => {
            console.log(error.text)
          },
        )
    } else setTouchCheckBox(true)
  }
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ padding: '1.2rem', maxWidth: '550px', backgroundColor: 'white' }}
    >
      <Typography
        component="div"
        variant="h5"
        sx={{
          color: COLOR.bg_MAIN,
        }}
      >
        Hãy tham gia cùng chúng tôi !
      </Typography>
      <Typography
        component="div"
        variant="span"
        sx={{
          color: COLOR.bg_TEXT_DARK_2,
          fontStyle: 'italic',
          mb: '2rem',
        }}
      >
        Đừng lo lắng, chỉ mất ít phút để bạn điền thông tin.
      </Typography>
      <TextFieldCustome
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        label="Email"
        id="email"
        name="email"
        desc="Bạn cần cung cấp email, chúng tôi sẽ phản hồi sớm nhất tại email này."
      />
      <TextFieldCustome
        value={formik.values.fullname}
        onChange={formik.handleChange}
        error={formik.touched.fullname && Boolean(formik.errors.fullname)}
        helperText={formik.touched.fullname && formik.errors.fullname}
        label="Họ tên"
        id="fullname"
        name="fullname"
        desc="Hãy nhập tên họ đầy đủ của bạn."
      />
      <TextFieldCustome
        label="Tên khóa học"
        id="coursename"
        name="coursename"
        desc="Bạn cần cung cấp tên khóa học, hãy tóm tắt tên khóa học của bạn."
        value={formik.values.coursename}
        onChange={formik.handleChange}
        error={formik.touched.coursename && Boolean(formik.errors.coursename)}
        helperText={formik.touched.coursename && formik.errors.coursename}
      />
      <FormControl sx={{ my: '1.2rem' }} fullWidth>
        <InputLabel id="demo-simple-select-label">Quốc gia</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectCountry}
          label="Quốc gia"
          onChange={(e) => setSelectCountry(e.target.value)}
        >
          {lstCountries.map((item, i) => (
            <MenuItem value={item.name} key={item.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormGroup>
          <FormControlLabel
            sx={{
              fontSize: '0.8rem',
              color: COLOR.bg_TEXT_DARK_2,
              fontStyle: 'italic',
              mt: '1rem',
            }}
            control={
              <Checkbox
                value={acceptPrivacy}
                onChange={(e) => {
                  setAcceptPrivacy(!acceptPrivacy)
                  setTouchCheckBox(true)
                }}
              />
            }
            label="Tôi chấp nhận điều khoản và sử dụng dịch vụ"
          />
        </FormGroup>
        {!acceptPrivacy && touchCheckBox && (
          <Typography
            component="div"
            variant="span"
            sx={{
              fontSize: '0.8rem',
              color: 'red',
            }}
          >
            Vui lòng chọn chấp nhận điều khoản của chúng tôi.
          </Typography>
        )}
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          backgroundColor: COLOR.bg_MAIN,
          color: COLOR.bg_WHITE,
          padding: '0.6rem',
          ':hover': {
            backgroundColor: COLOR.bg_MAIN,
          },
        }}
      >
        Đăng ký
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Bạn đã đăng ký thành công, vui lòng kiểm tra email để xem chi tiết
          thông tin !
        </Alert>
      </Snackbar>
    </form>
  )
}

export default TeacherForm
