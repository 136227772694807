import React from 'react'

const Course_Button = () => {
  return (
    <div className="course-availability" style={{ bottom: '0px' }}>
      <div className="container">
        <div className="d-flex d-flex justify-content-between align-items-center row">
          <div className="d-flex justify-content-center align-items-center col-lg-5 col-12">
            <p className="mb-2 mb-lg-0 font-svn-medium font-size-24 font-weight-700 w-100 text-sm-center text-sm-left">
              Đăng ký đặt chỗ ngay!
            </p>
          </div>
          <div className="col-lg-7 col-12">
            <div className="d-flex justify-content-around justify-content-sm-end align-items-center h-100">
              <button className="btn-outline">Tư vấn miễn phí</button>
              <a>
                <button>Đăng ký ngay</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course_Button
