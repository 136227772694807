import React from 'react'

const View_1 = () => {
  return (
    <div className="bg-f2">
      <div id="overview" className="container">
        <div className="style-layout-course-body d-flex justify-content-center align-items-center">
          <div className="col-sm-11">
            <div className="d-flex justify-content-center align-items-center">
              <p className="font-svn-medium font-size-36 font-size-mb-16 font-weight-700 text-center">
                Một khoá học về Lập trình JavaScript trong vòng 12 buổi với anh
                Hoàn Vũ
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default View_1
