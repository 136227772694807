import React from 'react'

const Data = []
const Review = () => {
  return (
    <div class="container">
      <div class="py-5">
        <div class="d-flex justify-content-center align-items-center">
          <div class="col-md-8">
            <p class="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 text-center">
              Học viên nói gì về chúng tôi?
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mb-5 pb-5">
          <div class="cohooz-1000 col-3 col-md-1"></div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12 col-lg-6 mb-4">
            <div class="position-relative style-card-phan-hoi">
              <p class="font-svn font-weight-400 color-616161 font-size-16 font-size-mb-14 font-style-italic">
                “Tuy thời gian học tại Boost không quá dài, nhưng cũng đủ để tôi
                thay đổi hoàn toàn góc nhìn về công nghệ thông tin nói chung và
                trí tuệ nhân tạo nói riêng. Với sự chuyên nghiệp của đội ngũ
                giảng viên, môi trường học cởi mở, tiến bộ và nội dung bài học
                được đầu tư kĩ lưỡng, chi tiết. Từ một người không biết gì về
                công nghệ, Boost đã giúp tôi phát triển bản thân tối đa. Tôi cảm
                thấy biết ơn Boost vì những điều Boost mang lại là rất tuyệt
                vời. Mong rằng Boost sẽ tiếp tục hoạt động vững mạnh, đào tạo
                thêm nhiều thế hệ sau này.”
              </p>
              <p class="font-svn-medium font-weight-700 font-size-18 font-size-mb-16 mb-0">
                Bạn Lương Đoàn Đức Minh
              </p>
              <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                Sinh viên Đại học Bách Khoa Hà Nội
              </p>
              <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                Kỹ sư AI
              </p>
              <div class="lazyload-wrapper ">
                <div class="style-card-user-feedback">
                  <img
                    src="https://res.cloudinary.com/di4m7zawx/image/upload/v1670993050/BOOST/4_oaczya.png"
                    alt=""
                    class="style-image-user-feedback"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6 pt-5 pt-lg-4">
            <div class="position-relative style-card-phan-hoi-2 h-100 d-flex justify-content-center align-items-center">
              <div class="">
                <p class="font-svn font-weight-400 color-616161 font-size-16 font-size-mb-14 font-style-italic">
                  “Nội dung của khoá học Full-stack tại Boost đã mở ra cho tôi
                  những kiến thức hoàn toàn mới mẻ như các khái niệm, kĩ thuật
                  và kinh nghiệm về trang web, lập trình,… mà không trường lớp
                  nào khác có được. Đội ngũ giảng viên, bài giảng, bài tập cả
                  những dự án tự luyện của Boost đều làm cho tôi thấy rất hài
                  lòng. Những trải nghiệm khi học, làm bài tập hay những bài
                  kiểm tra thử đã khiến tôi thấy đam mê hơn trong công việc hàng
                  ngày. Boost mang đến những kiến thức cả trực tiếp trong công
                  việc cả kiến thức cần thiết trong cuộc sống.Chúc Boost lớn
                  mạnh hơn nữa để có thể tạo thêm nhiều cơ hội học tập và trải
                  nghiệm cho các bạn trẻ tại Việt Nam.”
                </p>
                <p class="font-svn-medium font-weight-700 font-size-18 font-size-mb-16 mb-0">
                  Đinh Quyết Thắng
                </p>
                <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                  Sinh viên Đại học Bưu Chính Viễn Thông HN
                </p>
                <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                  Fullstack Developer
                </p>
                <div class="lazyload-wrapper ">
                  <div class="style-card-user-feedback-2">
                    <img
                      src="https://res.cloudinary.com/di4m7zawx/image/upload/v1671001038/BOOST/13_jxoi4c.png"
                      alt=""
                      class="style-image-user-feedback"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12 col-lg-6 mb-4">
            <div class="position-relative style-card-phan-hoi">
              <p class="font-svn font-weight-400 color-616161 font-size-16 font-size-mb-14 font-style-italic">
                “Một sự khác biệt đáng chú ý giữa Boost và những kiến thức mà
                tôi học được từ trường đại học là tính hệ thống. Bản chất của
                chương trình đào tạo tại Boost là ngay khi các sinh viên tốt
                nghiệp đi làm, họ luôn thấy rằng có một khoảng cách lớn giữa
                những gì họ học hỏi và thực tế và họ không biết làm thế nào để
                hệ thống hóa kiến thức của họ. Với những ưu điểm của chương
                trình quốc tế, được cập nhật liên tục, chương trình Boost tổ
                chức lại hệ thống kiến thức của người học, giúp người học hiểu
                rõ nhiều những lĩnh vực, giúp họ biết được những bước tiếp theo
                trong mọi trường hợp cụ thể.”
              </p>
              <p class="font-svn-medium font-weight-700 font-size-18 font-size-mb-16 mb-0">
                Hà Thái
              </p>
              <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                Sinh viên Đại học Bách Khoa Hà Nội
              </p>
              <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                Front End Developer
              </p>
              <div class="lazyload-wrapper ">
                <div class="style-card-user-feedback">
                  <img
                    src="https://res.cloudinary.com/di4m7zawx/image/upload/v1671199032/BOOST/Untitled_x3frkw.png"
                    alt=""
                    class="style-image-user-feedback"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-6 pt-5 pt-lg-4">
            <div class="position-relative style-card-phan-hoi-2 h-100 d-flex justify-content-center align-items-center">
              <div class="">
                <p class="font-svn font-weight-400 color-616161 font-size-16 font-size-mb-14 font-style-italic">
                  “Trải qua nhiều những khóa học tại Boost, tôi đã thực sự có
                  một trải nghiệm hết sức khác biệt về chương trình học này: 1.
                  Tôi nhận thấy đội ngũ giảng viên của từng khóa học đều rất
                  chuyên nghiệp, kinh nghiệm và cả giỏi giang. 2. Kiến thức về
                  bài học khá toàn diện với đầy đủ các khái niệm và nội dung để
                  đào tạo nên một lập trình viên tầm cỡ. 3. Bài giảng rất thực
                  tế và sinh động với đầy đủ các dẫn chứng thực tiễn từ các
                  doanh nghiệp và tập đoàn lớn nhỏ trên thế giới, và những con
                  số thống kê hết sức chân thực để minh họa cho bài giảng. Tôi
                  rất sẵn sàng tham gia các khóa học nâng cao về các kĩ năng cần
                  thiết nếu có trong tương lai.”
                </p>
                <p class="font-svn-medium font-weight-700 font-size-18 font-size-mb-16 mb-0">
                  Quang Nguyễn
                </p>
                <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                  Sinh viên Đại học Phenika Kỹ sư ML
                </p>
                <p class="font-svn-medium font-weight-400 font-size-14 color-616161 mb-0">
                  Fullstack Developer
                </p>
                <div class="lazyload-wrapper ">
                  <div class="style-card-user-feedback-2">
                    <img
                      src="https://res.cloudinary.com/di4m7zawx/image/upload/v1671200941/BOOST/2_rewbor.png"
                      alt=""
                      class="style-image-user-feedback"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Review
