import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  Typography,
} from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import '../../static/css/teacher.css'
import { COLOR } from '../../constants/color'
import { useFormik } from 'formik'
import { validationSchema } from '../../utils/teacherValidate'
import TextFieldCustome from '../teacher/components/custome_textfield'
const data = require('../../dummyData/course.json')

const lstPayment = [
  'Momo',
  'Thanh toán bằng thẻ nội địa',
  'Nạp tiền',
  'Thanh toán bằng visa',
]

const PayForm = () => {
  const { idCourse } = useParams()
  const findCourse = data.find((m) => m.id === parseInt(idCourse))
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      email: 'hd13112011@gmail.com',
      fullname: 'Mikah Dang',
      coursename: findCourse.title,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmitModal(values),
  })
  const [selectPayment, setSelectPayment] = React.useState('Momo')
  const [open, setOpen] = React.useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const handleSubmitModal = (values) => {
    const { email, fullname, coursename } = values
    if (findCourse) {
      const data = {
        to_name: fullname,
        to_email: email,
        course_name: findCourse.title,
        teacher: findCourse.author,
      }

      emailjs
        .send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          'template_pws6ogq',
          data,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY,
        )
        .then(
          (result) => {
            setOpen(true)
            setTimeout(() => {
              setOpen(false)
              navigate('/')
            }, 3000)
          },
          (error) => {
            console.log(error.text)
          },
        )
    }
  }
  const handleRandomId = () => {
    let id = ''
    for (let i = 0; i < 9; i++) {
      id += Math.floor(Math.random() * 9).toString()
    }
    return id
  }
  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ padding: '1.2rem', maxWidth: '550px', backgroundColor: 'white' }}
    >
      <Typography
        component="div"
        variant="h5"
        sx={{
          color: COLOR.bg_MAIN,
        }}
      >
        Xác nhận thông tin thanh toán của bạn.
      </Typography>
      <Typography
        component="div"
        variant="span"
        sx={{
          color: COLOR.bg_TEXT_DARK_2,
          fontStyle: 'italic',
          mb: '2rem',
        }}
      >
        Đừng lo lắng, chỉ mất ít phút để bạn điền thông tin.
      </Typography>
      <TextFieldCustome
        label="Mã hóa đơn"
        id="idHd"
        name="idHd"
        value={handleRandomId()}
        disable={true}
      />
      <TextFieldCustome
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        label="Email"
        id="email"
        name="email"
      />
      <TextFieldCustome
        value={formik.values.fullname}
        onChange={formik.handleChange}
        error={formik.touched.fullname && Boolean(formik.errors.fullname)}
        helperText={formik.touched.fullname && formik.errors.fullname}
        label="Họ tên"
        id="fullname"
        name="fullname"
        desc="Hãy nhập tên họ đầy đủ của bạn."
      />
      <TextFieldCustome
        label="Tên khóa học"
        id="coursename"
        name="coursename"
        value={formik.values.coursename}
        onChange={formik.handleChange}
        error={formik.touched.coursename && Boolean(formik.errors.coursename)}
        helperText={formik.touched.coursename && formik.errors.coursename}
        disable={true}
      />
      <TextFieldCustome
        label="Tổng tiền"
        id="total"
        name="total"
        value={findCourse.price}
        disable={true}
      />
      <FormControl sx={{ my: '1.2rem' }} fullWidth>
        <InputLabel id="demo-simple-select-label">
          Phương thức thanh toán
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectPayment}
          label="Phương thức thanh toán"
          onChange={(e) => setSelectPayment(e.target.value)}
        >
          {lstPayment.map((item, i) => (
            <MenuItem value={item} key={i}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          backgroundColor: COLOR.bg_MAIN,
          color: COLOR.bg_WHITE,
          padding: '0.6rem',
          ':hover': {
            backgroundColor: COLOR.bg_MAIN,
          },
        }}
      >
        Thanh toán
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Bạn đã thanh toán khóa học thành công, vui lòng kiểm tra email của bạn
          để cập nhật thông tin khóa học !
        </Alert>
      </Snackbar>
    </form>
  )
}

export default PayForm
