import React from 'react'

const Quickview = () => {
  return (
    <div className="container">
      <div className="style-layout-course-body bg-fa">
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="mb-3 col-6">
                <p className="style-text-col-tham-gia">SẮP DIỄN RA</p>
                <p className="style-text-col-tham-gia-khoa-hoc">KHOÁ HỌC 01</p>
              </div>
              <div className="mb-3 col-6">
                <p className="style-text-col-tham-gia">HẠN ĐĂNG KÝ</p>
                <p className="style-text-col-tham-gia-khoa-hoc">
                  31 THÁNG 01, 2023
                </p>
              </div>
              <div className="mb-3 mb-sm-0 col-6">
                <p className="style-text-col-tham-gia">THỜI GIAN KHOÁ HỌC</p>
                <p className="style-text-col-tham-gia-khoa-hoc">
                  THÁNG 2, 2023
                </p>
              </div>
              <div className="mb-3 mb-sm-0 col-6">
                <p className="style-text-col-tham-gia">LỊCH HỌC</p>
                <p className="style-text-col-tham-gia-khoa-hoc">
                  14:00 - 15:30
                  <br />
                  Thứ Hai và Thứ Năm
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <a>
              <button className="landing-btn undefined undefined btn btn-primary btn-block">
                Đăng ký ngay
              </button>
            </a>
            <button
              type="button"
              className="landing-btn mt-3 btn btn-outline-primary btn-block"
            >
              Tư vấn miễn phí
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quickview
