import React from 'react'

const Banner = () => {
  return (
    <div className="course-banner">
      <div className="align-items-center row">
        <div className="col-sm-6 col-12">
          <h2>Javascript Hero</h2>
          <h3
            style={{
              fontFamily: 'system-ui',
            }}
          >
            Thành thạo ngôn ngữ lập trình phổ biến nhất Thế giới
          </h3>
        </div>
        <div className="col-sm-6 col-12">
          <img src="/image/course-javascript/banner.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Banner
