import React from 'react'

const Partner = () => {
  return (
    <div>
      <div className="position-relative">
        <div className="position-absolute h-100 w-100">
          <img
            src="https://www.cohooz.com/image/landingnew/bg-doitac.svg"
            alt=""
            className=""
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
        <div className="container">
          <div className="py-5">
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-md-8">
                <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 text-center">
                  Đối tác của Boost là ai?
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mb-5 pb-5">
              <div className="cohooz-1000 col-3 col-md-1"></div>
            </div>
            <div className="row">
              <div className="pl-lg-3 pr-lg-3 col-lg-4">
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center mb-5"
                    style={{ height: '250px' }}
                  >
                    <div className="lazyload-wrapper ">
                      <img
                        src="https://img.freepik.com/premium-vector/cartoon-illustration-thai-female-teacher-holding-stick-front-blackboard_49924-213.jpg?w=740"
                        alt=""
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </div>
                  <p className="font-svn-medium font-size-16 font-weight-700 color-4754e2 text-center">
                    Giảng viên/Chuyên gia/Chuyên viên{' '}
                  </p>
                  <p className="font-svn font-size-16 font-weight-400 text-center">
                    Bạn tự tin về kiến thức chuyên môn và đang có ý tưởng chia
                    sẻ tri thức của mình đến cộng đồng
                  </p>
                </div>
              </div>
              <div className="pl-lg-3 pr-lg-3 col-lg-4">
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center mb-5"
                    style={{ height: '250px' }}
                  >
                    <div className="lazyload-wrapper ">
                      <img
                        src="https://img.freepik.com/free-vector/diverse-business-people-multiracial-group-row_107791-10606.jpg?w=1060&t=st=1670954457~exp=1670955057~hmac=06a5db3e05d7fc4dcec75865b854f7536e261f3a302ce05d1d94ac079b33bedf"
                        alt=""
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </div>
                  <p className="font-svn-medium font-size-16 font-weight-700 color-4754e2 text-center">
                    Doanh nhân/Nhân sự cấp cao
                  </p>
                  <p className="font-svn font-size-16 font-weight-400 text-center">
                    Bạn muốn gia tăng nguồn thu nhập đồng thời tạo giá trị cho
                    xã hội{' '}
                  </p>
                </div>
              </div>
              <div className="pl-lg-3 pr-lg-3 col-lg-4">
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center mb-5"
                    style={{ height: '250px' }}
                  >
                    <div className="lazyload-wrapper ">
                      <img
                        src="https://img.freepik.com/free-vector/influencer-social-media-concept_52683-46422.jpg?w=740&t=st=1670954513~exp=1670955113~hmac=47017d11d1b11da6decba4d114792a302b67eb9f3752ce7e436ab73c98b301a4"
                        alt=""
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  </div>
                  <p className="font-svn-medium font-size-16 font-weight-700 color-4754e2 text-center">
                    Influencer/Content creator
                  </p>
                  <p className="font-svn font-size-16 font-weight-400 text-center">
                    Bạn muốn nâng tầm thương hiệu cá nhân và sức ảnh hưởng trong
                    cộng đồng của mình
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partner
