import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Context from './context/global'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { theme } from './constants/theme'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Context>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Context>
    ;
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
