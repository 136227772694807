import React from 'react'
const Logo = require('../../static/image/logo/logo.png')
const Header_Detail_Course = () => {
  return (
    <div className="style-layout-course z-index-100">
      <nav className="navbar navbar-expand-lg style-navbar-pc">
        <a className="" href="/"></a>
        <div className="d-none d-sm-none d-lg-block">
          <div className="navbar-course mr-auto p-2">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="#overview" className="nav-link">
                  TỔNG QUAN
                </a>
              </li>
              <li className="nav-item">
                <a href="#instructor" className="nav-link">
                  GIẢNG VIÊN
                </a>
              </li>
              <li className="nav-item">
                <a href="#wall_of_love" className="nav-link">
                  PHẢN HỒI HỌC VIÊN
                </a>
              </li>
              <li className="nav-item">
                <a href="#faq" className="nav-link">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header_Detail_Course
