import React from 'react'

const img1 = 'https://www.cohooz.com/image/course-javascript/benefit.png'
const Course_Benefit = () => {
  return (
    <div className="course-benefit">
      <div className="container">
        <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 mb-0 color-6dafa7 text-center">
          Sau khóa học, bạn được gì
        </p>
        <img src={img1} alt="Benefit" />
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>1.</span>
                Nền tảng lập trình vững chắc, tích luỹ kinh nghiệm thực chiến từ
                các dự án thực tế, có thể áp dụng khi đi làm ngay.
              </p>
            </div>
          </div>
          <div className="d-none d-sm-block col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>5.</span>
                Cơ hội mở rộng networking với khách hàng là các công ty hàng đầu
                trong nước và trên thế giới như Vietnam Airlines, TranSwap
                (Singapore), Dogoodpoints (Mỹ), Authoritas (Vương quốc Anh),
                v.v.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>2.</span>
                Nắm được cơ bản ngôn ngữ lập trình JavaScript: ngôn ngữ phổ biến
                nhất thế giới, tiện lợi, có tính ứng dụng cao cho cả website và
                mobile, đặc biệt thích hợp với những người bắt đầu học Lập
                trình.
              </p>
            </div>
          </div>
          <div className="d-none d-sm-block col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>6.</span>
                Được tiếp cận với nguồn tài liệu đồ sộ, trải dài trên nhiều lĩnh
                vực của Boost như Quản lý tài chính cá nhân, Viết lách chuyên
                nghiệp, Tiếng Anh giao tiếp, Thiết kế slide, Digital Marketing,
                v.v.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>3.</span>
                Được giảng viên định hướng, tư vấn và giúp đỡ trọn đời về Lập
                trình và Công nghệ.
              </p>
            </div>
          </div>
          <div className="d-none d-sm-block col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>7.</span>
                Cơ hội nhận được các suất học bổng lên tới 50% cho mọi khóa học
                về Công nghệ trên nền tảng Boost nếu tốt nghiệp xuất sắc.
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>4.</span>
                Được phát triển các kỹ năng mềm như làm việc nhóm, quản lý dự
                án, UI/UX và kỹ năng tuyển dụng (CV, phỏng vấn) thông qua các
                chương trình kết nối việc làm hấp dẫn.
              </p>
            </div>
          </div>
          <div className="d-block d-sm-none col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>5.</span>
                Cơ hội mở rộng networking với khách hàng là các công ty hàng đầu
                trong nước và trên thế giới như Vietnam Airlines, TranSwap
                (Singapore), Dogoodpoints (Mỹ), Authoritas (Vương quốc Anh),
                v.v.
              </p>
            </div>
          </div>
          <div className="d-block d-sm-none col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>6.</span>
                Được tiếp cận với nguồn tài liệu đồ sộ, trải dài trên nhiều lĩnh
                vực của Boost như Quản lý tài chính cá nhân, Viết lách chuyên
                nghiệp, Tiếng Anh giao tiếp, Thiết kế slide, Digital Marketing,
                v.v.
              </p>
            </div>
          </div>
          <div className="d-block d-sm-none col-12 col-sm-6">
            <div className="benefit-item mb-4">
              <p className="font-size-24 font-size-mb-18 position-relative">
                <span>7.</span>
                Cơ hội nhận được các suất học bổng lên tới 50% cho mọi khóa học
                về Công nghệ trên nền tảng Boost nếu tốt nghiệp xuất sắc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course_Benefit
