import React from 'react'
const Images = {
  img1: 'https://www.cohooz.com/image/course-javascript/overview-1.png',
  img2: 'https://www.cohooz.com/image/course-javascript/overview-2.png',
  img3: 'https://www.cohooz.com/image/course-javascript/overview-3.png',
}
const Course_Overview = () => {
  return (
    <div>
      <div className="course-overview">
        <div className="container">
          <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 mb-5 color-6dafa7 text-center">
            Tại sao bạn nên chọn JavaScript để bắt đầu con đường lập trình
            chuyên nghiệp ngay từ hôm nay?
          </p>
          <div className="align-items-center mb-4 row">
            <div className="col-12 col-sm-6 col-md-5">
              <figure className="text-center mb-3 mb-sm-0">
                <img src={Images.img1} alt="" />
                <figcaption className="font-size-14 text-bold text-center mt-3">
                  Các ngôn ngữ lập trình các Website thường sử dụng
                </figcaption>
              </figure>
            </div>
            <div className="col-12 col-sm-6 col-md-7">
              <p className="font-size-24 font-size-mb-18 text-bold text-black mb-4">
                JavaScript giúp bạn nhanh chóng nắm bắt lợi thế khi gia nhập
                ngành CNTT
              </p>
              <p className="style-text-SVN-Regular font-size-18 font-size-mb-14 font-weight-400 line-height-180 mb-0">
                Theo báo cáo của GitHub vào tháng 12/2020, JavaScript là ngôn
                ngữ phổ biến nhất trên thế giới. Có hơn
                <span className="text-bold color-4754e2">1,8 tỷ</span>
                website trên toàn thế giới, trong đó có tới '95%' website đang
                sử dụng JavaScript. Đây là một ngôn ngữ lập trình vô cùng đa
                năng, chạy được trên đa nền tảng, đa trình duyệt. Bên cạnh ứng
                dụng trên Mobile, Desktop, hiện nay JavaScript còn được mở rộng
                phát triển sang mảng Game. Đặc biệt, JavaScript hỗ trợ nhiều
                chức năng có giá trị dài hạn, giúp bạn dễ dàng áp dụng cho bất
                kỳ ngôn ngữ nào khác như Java, Python và C++.
              </p>
            </div>
          </div>
          <div className="align-items-center mb-4 row">
            <div className="order-2 col-12 col-sm-6 col-md-5">
              <figure className="text-center mb-3 mb-sm-0">
                <img src={Images.img2} alt="" />
              </figure>
            </div>
            <div className="order-1 col-12 col-sm-6 col-md-7">
              <p className="font-size-24 font-size-mb-18 text-bold text-black mb-4">
                JavaScript thân thiện với người mới bắt đầu
              </p>
              <p className="style-text-SVN-Regular font-size-18 font-size-mb-14 font-weight-400 line-height-180 mb-0">
                JavaScript không phải là ngôn ngữ có cấu trúc phức tạp. Bên cạnh
                đó, do ngôn ngữ này đã được cài đặt sẵn trên mọi trình duyệt web
                ngày nay, nên bạn hoàn toàn có thể bắt tay vào viết mã thay vì
                phải cài đặt những phần mềm phức tạp trên máy tính của mình.
              </p>
            </div>
          </div>
          <div className="align-items-center mb-4 row">
            <div className="col-12 col-sm-6 col-md-5">
              <figure className="text-center mb-3 mb-sm-0">
                <img src={Images.img3} alt="" />
              </figure>
            </div>
            <div className="col-12 col-sm-6 col-md-7">
              <p className="font-size-24 font-size-mb-18 text-bold text-black mb-4">
                JavaScript mang đến cơ hội nghề nghiệp và
                <br className="d-none d-xl-block" />
                thu nhập hấp dẫn
              </p>
              <p className="style-text-SVN-Regular font-size-18 font-size-mb-14 font-weight-400 line-height-180 mb-0">
                Theo DevSkiller 2020, JavaScript là một trong năm kỹ năng được
                săn đón nhiều nhất bởi các nhà tuyển dụng trên toàn cầu. Tại
                Việt Nam, có đến
                <span className="text-bold color-4754e2">hơn 70%</span>
                công ty đang tìm kiếm nhà phát triển JavaScript. Về mức lương,
                các bạn lập trình viên JavaScript từ 1- 2 năm kinh nghiệm có thể
                hưởng thu nhập lên tới
                <span className="text-bold color-4754e2">
                  544$ (khoảng 12,5 triệu/tháng)
                </span>
                , cao hơn so với các ngôn ngữ lập trình phổ biến khác như C++,
                C# và PHP.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Course_Overview
