export const IMAGES = {
  IMG_1: require('../static/image/landingnew/check1.png'),
  IMG_2: require('../static/image/landingnew/im1.jpg'),
  IMG_3:
    'https://res.cloudinary.com/di4m7zawx/image/upload/v1670994448/BOOST/im1_i2w7oz.jpg',
  IMG_4: 'https://www.cohooz.com/image/landingnew/homesliderbg.svg',
  IMG_5: 'https://www.cohooz.com/image/landingnew/bg-3.png',
  IMG_6: 'https://www.cohooz.com/image/landingnew/bg-doitac.svg',
  IMG_7: require('../static/image/landingnew/check1.png'),
}
