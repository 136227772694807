import React from 'react'
import Slider from 'react-slick'
import { Typography, Card, Stack, Button, Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star'
const data = require('../../../dummyData/course.json')

export const SlickItems = ({ handleActiveModal, item }) => {
  const location = useLocation()
  const pathname = location.pathname
  console.log(pathname)
  return (
    <Card sx={{ mx: 2 }}>
      <Stack sx={{ position: 'relative' }}>
        <Link to="/detail-course">
          <img
            style={{ height: '250px', width: '100%' }}
            src={item.backgroundImg}
            alt=""
          />
        </Link>
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            p: 1,
            bottom: 10,
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            {item.title}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: 'white',
              fontStyle: 'italic',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {item.desc}
          </Typography>
        </Stack>
        {item.isUpdate && (
          <Box
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              borderRadius: '10px',
              padding: '3px 10px',
              fontStyle: 'italic',
              fontWeight: '400',
              height: '30px',
              maxWidth: '200px',
              fontSize: '14px',
              color: 'black',
              backgroundColor: 'white',
            }}
          >
            Đang cập nhật
          </Box>
        )}
      </Stack>
      <Stack sx={{ my: 2 }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 2,
          }}
        >
          {!item.isUpdate && (
            <img
              src={item.img}
              style={{
                height: '50px',
                width: '50px',
                borderRadius: '50%',
              }}
            />
          )}
          <Stack sx={{ ml: 2 }}>
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              {item.isUpdate ? 'Đang cập nhật' : item.author}
            </Typography>
            <Typography>
              {item.isUpdate ? 'Đang cập nhật' : item.position}
            </Typography>
            {!item.isUpdate ? (
              <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                <StarIcon color="primary" />
                <Typography ml={1} textAlign="center" fontWeight="bold">
                  {item.starPoint}{' '}
                  <span style={{ fontWeight: '200', color: 'black' }}>
                    ({item.starCount})
                  </span>{' '}
                  - {item.rateTime}
                </Typography>
              </Stack>
            ) : (
              <Stack sx={{ paddingBlock: '13px' }}></Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ p: 2 }}>
        {pathname === '/mycourse' ? (
          <Button
            variant="contained"
            fullWidth
            sx={{ color: 'white' }}
            onClick={() => handleActiveModal(item.id)}
          >
            Thanh toán
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            sx={{ color: 'white' }}
            onClick={() => handleActiveModal(item.id)}
          >
            Tham gia ngay
          </Button>
        )}
      </Stack>
    </Card>
  )
}
const Slideshow = ({ context, modal, courseRef }) => {
  const [currentID, setCurrentId] = modal
  const [acitiveModal, setAcitiveModal] = context
  const handleActiveModal = (id) => {
    setCurrentId(id)
    setAcitiveModal(!acitiveModal)
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div id="khoa-hoc" classNameName="container" ref={courseRef}>
      <div classNameName="py-5">
        <div id="khoa-hoc" class="container">
          <div class="py-5">
            <div class="d-flex justify-content-center align-items-center mb-5">
              <div>
                <div class="text-center col-lg-12">
                  <p class="text-center font-svn-bold font-weight-700 font-size-36 font-size-mb-24">
                    Khám phá ngay khóa học của chúng tôi
                  </p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="cohooz-1000 col-3 col-md-2"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div className="row items-center">
                <Slider {...settings}>
                  {data.map((item, _) => (
                    <SlickItems
                      key={_}
                      item={item}
                      handleActiveModal={() => handleActiveModal(item.id)}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slideshow
