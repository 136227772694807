import React from 'react'

const List_Coures = () => {
  return (
    <div id="list-course" className="bg-fd">
      <div id="curriculum" className="container">
        <div className="style-layout-course-body">
          <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 color-4754e2 mb-5">
            Chi tiết về chương trình học
          </p>
          <div className="col-lg-12">
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  01
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Tổng quan về JavaScript
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Khám phá lịch sử phát triển của JavaScript
                  <br />
                  Làm quen với tư duy lập trình JavaScript
                  <br />
                  Nắm được các khái niệm cơ bản về biến, kiểu dữ liệu, toán tử,
                  vòng lặp, chuỗi, mảng, v.v.
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  02
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Kiểu dữ liệu và các biến (Data types and variables)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Tìm hiểu về các biến, cách khai báo biến và một số vấn đề
                  thường gặp phải khi làm việc với biến trong JS
                  <br />
                  Biết cách sử dụng các kiểu dữ liệu cơ bản trong JS như Number,
                  String, Boolean, Undefine, Null, Object, Array và RegExp
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  03
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Câu điều kiện (Conditionals)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Làm quen với câu điều kiện, một trong những câu lệnh được sử
                  dụng nhiều nhất trong lập trình
                  <br />
                  Thực hành giải quyết các vấn đề trong cuộc sống bằng “tư duy
                  lập trình”
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  04
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Vòng lặp (Loops)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Hiểu rõ về các cấu trúc lặp trong JS giúp tiết kiệm thời gian
                  trong việc viết những đoạn mã cần thực thi nhiều lần liên
                  tiếp.
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  05
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Các hàm (Functions)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Tìm hiểu về các hàm cơ bản trong JS
                  <br />
                  Thực hành khai báo hàm và gọi hàm bài bản
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  06
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Mảng (Array)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Khám phá về mảng, kiểu dữ liệu rất phổ biến trong ngôn ngữ lập
                  trình JS
                  <br />
                  Thực hành khai báo, khởi tạo và xử lý mảng
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  07
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Đối tượng (Objects)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Nắm rõ khái niệm Object trong JS
                  <br />
                  Tìm hiểu về các đặc tính và phương pháp của Object trong JS
                  <br />
                  Thực hành thao tác với các đặc tính và phương pháp của Object
                  trong JS
                </p>
              </div>
            </div>
            <div className="mb-3 row">
              <div className="position-relative col-2 col-lg-1">
                <p className="style-text-SVN-Regular font-size-24 font-size-mb-24 font-weight-700 text-center">
                  08
                </p>
                <div className="style-border-col-list"></div>
              </div>
              <div className="col-10 col-lg-11">
                <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700">
                  Đồ án tốt nghiệp (Capstone project)
                </p>
                <p className="font-svn font-size-18 font-size-mb-14 font-weight-nornal line-height-180">
                  Áp dụng những kiến thức xuyên suốt khóa học để thực chiến trên
                  các dự án như Boost và hàng ngàn dự án đặc biệt khác.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default List_Coures
