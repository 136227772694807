import React from 'react'
import Footer from '../../components/footer'
import {
  Box,
  Modal as ModalShow,
  Typography,
  Stack,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Divider,
  TextField,
  Button,
  Tabs,
  Tab,
  Card,
} from '@mui/material'
import Header from '../../components/header'
import '../../static/css/10.83141e29.chunk.css'
import '../../static/css/extention.css'
import '../../static/css/main.c5644251.chunk.css'
import '../../static/css/modal.css'
import { SlickItems } from '../home/components/slideshow'
import Modal from '../home/components/modal'
import { GlobalContext } from '../../context/global'
import TabCourse from './components/tabCourse'
import TabResourse from './components/tabResource'

const allMenu = [
  {
    title: 'Thanh toán',
    tab: 'tab1',
    menu: [
      {
        id: 1,
        title: 'Đã thanh toán',
      },
      {
        id: 2,
        title: 'Chưa thanh toán',
      },
      {
        id: 3,
        title: 'Thanh toán không thành công',
      },
      {
        id: 4,
        title: 'Đã hủy',
      },
    ],
  },
  {
    title: 'Khóa học của tôi',
    tab: 'tab2',
    menu: [
      {
        id: 1,
        title: 'Khóa học',
      },

      {
        id: 2,
        title: 'Tài nguyên',
      },
      {
        id: 3,
        title: 'Đánh giá khóa học',
      },
    ],
  },
]

const MenuItems = ({ id, title, tab, onPress, currentID, endLength }) => {
  return (
    <React.Fragment>
      <ListItem
        sx={{
          backgroundColor:
            id === currentID.value && tab === currentID.tab
              ? 'rgba(0, 0, 0, 0.04)'
              : 'none',
        }}
        disablePadding
      >
        <ListItemButton onClick={() => onPress(id)}>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
      {id < endLength && <Divider />}
    </React.Fragment>
  )
}

const Course = () => {
  const globalContext = React.useContext(GlobalContext)
  const { courses, setCourses } = globalContext
  const [activeModal, setActiveModal] = React.useState(false)
  const [currentID, setCurrentId] = React.useState(null)
  const [index, setIndex] = React.useState({
    tab: allMenu[0].tab,
    value: allMenu[0].menu[0].id,
  })
  const titleRef = React.useRef()
  const courseRef = React.useRef()
  const contactRef = React.useRef()
  console.log('courses', courses)
  const handleActiveModal = (id) => {
    setCurrentId(id)
    setActiveModal(!activeModal)
  }
  return (
    <div className="section full-height bg-white" style={{ padding: 0 }}>
      <div>
        <Header
          contactRef={contactRef}
          courseRef={courseRef}
          titleRef={titleRef}
          context={[activeModal, setActiveModal]}
        />
        <div className="">
          <Stack sx={{ display: 'flex', flexDirection: 'row', p: 3 }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                flex: 1,
              }}
            >
              {allMenu.map((e) => (
                <React.Fragment>
                  <Typography component="h4" fontWeight="bold">
                    {e.title}
                  </Typography>
                  <nav aria-label="main mailbox folders">
                    <List>
                      {e.menu.map((item) => (
                        <MenuItems
                          endLength={e.menu.length}
                          key={item.id}
                          title={item.title}
                          id={item.id}
                          tab={e.tab}
                          onPress={(id) =>
                            setIndex({
                              tab: e.tab,
                              value: id,
                            })
                          }
                          currentID={index}
                        />
                      ))}
                    </List>
                  </nav>
                </React.Fragment>
              ))}
            </Box>
            <Box
              sx={{
                mx: 2,
                backgroundColor: 'black',
                width: '1px',
                minHeight: '100px',
              }}
            ></Box>
            <Box
              sx={{
                flex: 4,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {courses.length > 0 &&
                courses
                  .filter((m) => m.tab === index.tab && m.value === index.value)
                  .map((item) => (
                    <Stack width="48%" mb={2}>
                      {' '}
                      <SlickItems
                        key={item.id}
                        handleActiveModal={() => handleActiveModal(item.id)}
                        item={item}
                      />
                    </Stack>
                  ))}
              {index.tab === 'tab2' && index.value === 2 && <TabResourse />}
              {index.tab === 'tab2' && index.value === 1 && <TabCourse />}
            </Box>
          </Stack>
          <Footer contactRef={contactRef} />
          {currentID && (
            <Modal
              modal={[currentID, setCurrentId]}
              context={[activeModal, setActiveModal, courses, setCourses]}
            />
          )}
          <ModalShow
            open={index.tab === 'tab2' && index.value === 3}
            onClose={() =>
              setIndex({
                tab: 'tab1',
                value: 1,
              })
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <h6 style={{ textAlign: 'center' }}>
                <i> Vui lòng điền đầy đủ thông tin kỹ năng mà bạn muốn học</i>
              </h6>
              <TextField
                id="outlined-basic"
                label="Email giảng viên"
                variant="outlined"
                fullWidth
                sx={{
                  mt: 2,
                  mb: 2,
                }}
              />
              <TextField
                multiline
                rows={4}
                id="outlined-basic"
                label="Nội dung"
                variant="outlined"
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                }}
              >
                Gửi đánh giá
              </Button>
            </Box>
          </ModalShow>
        </div>
      </div>
    </div>
  )
}

export default Course
