import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout_With_Header from '../components/admin/layout_with_header'
import { adminRoutes, mainRoutes } from '../constants/mainRoutes'
const RouterMain = () => {
  return (
    <Router>
      <Routes>
        {mainRoutes.map((item, _) => {
          const pages = <item.component />
          return <Route path={item.path} exac key={_} element={pages} />
        })}
        {adminRoutes.map((item, _) => {
          const pages = (
            <Layout_With_Header>
              <item.component />
            </Layout_With_Header>
          )
          return <Route path={item.path} exac key={_} element={pages} />
        })}
      </Routes>
    </Router>
  )
}

export default RouterMain
