import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
const columns = [
  { field: 'id', headerName: 'Số thứ tự', flex: 1 },
  { field: 'type', headerName: 'Loại tài liệu', flex: 3 },
  { field: 'fileName', headerName: 'Tên tài liệu', flex: 4 },
  {
    field: 'action',
    headerName: 'Hành động',
    flex: 2,
    renderCell: (params) => {
      return (
        <Button
          startIcon={<DownloadIcon />}
          variant="contained"
          sx={{ color: 'white' }}
        >
          Download
        </Button>
      )
    },
  },
]
const rows = [
  {
    id: 1,
    type: 'Dữ liệu',
    fileName: 'c++_189124555.csv',
  },
  {
    id: 2,
    type: 'Dữ liệu',
    fileName: 'htmlcss_18912455553455.csv',
  },
]
export default function TabResourse() {
  return (
    <DataGrid
      sx={{
        my: 2,
      }}
      rows={rows}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      autoHeight
    />
  )
}
