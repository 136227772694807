import { Box, Button, Modal, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../static/css/teacher.css'

const StudentForm = () => {
  const navigate = useNavigate()
  const [activeModal, setActiveModal] = React.useState(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
  }
  return (
    <form action="" method="">
      <h1 style={{ textAlign: 'center' }}>
        Vui lòng điền đầy đủ thông tin kỹ năng mà bạn muốn học
      </h1>
      <div className="contentform">
        <div className="leftcontact">
          <div className="form-group">
            <p>
              Email
              <span>*</span>
            </p>
            <span className="icon-case">
              <i class="fa-light fa-mailbox"></i>
            </span>
            <input
              type="text"
              name="nom"
              id="nom"
              data-rule="required"
              data-msg="Vérifiez votre saisie sur les champs : Le champ 'Nom' doit être renseigné."
            />
            <div className="validation"></div>
          </div>

          <div className="form-group">
            <p>
              Kĩ năng muốn học
              <span>*</span>
            </p>
            <span className="icon-case">
              <i className="fa fa-envelope-o"></i>
            </span>
            <input
              type="email"
              name="email"
              id="email"
              data-rule="email"
              data-msg="Vérifiez votre saisie sur les champs : Le champ 'E-mail' est obligatoire."
            />
            <div className="validation"></div>
          </div>
        </div>
      </div>
      <div className="rightcontact">
        <div className="form-group">
          <p>
            Họ tên
            <span>*</span>
          </p>
          <span className="icon-case">
            <i className="fa fa-building-o"></i>
          </span>
          <input
            type="text"
            name="ville"
            id="ville"
            data-rule="required"
            data-msg="Vérifiez votre saisie sur les champs : Le champ 'Ville' doit être renseigné."
          />
          <div className="validation"></div>
        </div>
        <div class="form-group">
          <p>Mô tả</p>
          <span class="icon-case">
            {/* <i class="fa fa-comments-o"></i> */}
          </span>
          <textarea
            name="message"
            rows="14"
            data-rule="required"
            data-msg="Vérifiez votre saisie sur les champs : Le champ 'Message' doit être renseigné."
          ></textarea>
          <div class="validation"></div>
        </div>
      </div>

      <div
        style={{
          color: 'red',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          width: '100%',
        }}
      >
        Chúng tôi sẽ phản hồi đến email của bạn trong thời gian sớm nhất.
      </div>
      <button
        onClick={() => setActiveModal(!activeModal)}
        type="button"
        className="bouton-contact"
      >
        Send
      </button>
      <Modal
        open={activeModal}
        onClose={() => setActiveModal(!activeModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Thông báo
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Bạn đã gửi yêu cầy thành công
          </Typography>
          <Stack direction="row" sx={{ float: 'right' }} spacing={1} mt={5}>
            <Button
              onClick={() => {
                setActiveModal(false)
                navigate('/')
              }}
              variant="contained"
              sx={{
                backgroundColor: '#f1880e ',
                ':hover': {
                  backgroundColor: '#f1880e ',
                },
              }}
            >
              OK
            </Button>
            <Button
              onClick={() => setActiveModal(false)}
              variant="contained"
              sx={{
                backgroundColor: '#eeeeee ',
                color: 'black',
                ':hover': {
                  backgroundColor: '#eeeeee ',
                },
              }}
            >
              CANCEL
            </Button>
          </Stack>
        </Box>
      </Modal>
    </form>
  )
}

export default StudentForm
