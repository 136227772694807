import React from 'react'

const About = ({ titleRef }) => {
  return (
    <div id="cohooz" className="container" ref={titleRef}>
      <div className="py-5">
        <div>
          <p className="font-svn-medium font-size-44 font-weight-700 font-size-mb-24 text-center">
            Về Boost
          </p>
          <div className="d-flex justify-content-center align-items-center mb-5">
            <div className="cohooz-1000 col-3 col-md-1"></div>
          </div>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400 color-616161 text-center mb-5">
            Boost là một nền tảng cung cấp các khoá học trực tuyến kiểu mới theo
            hình thức cohort-based
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-700 text-center mb-3">
            Khoá học trực tuyến theo hình thức cohort là gì?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400 text-center color-616161 mb-5">
            Khác với hình thức học trực tuyến qua video thu sẵn, lớp học trực
            tuyến theo hình thức cohort giúp học viên có cơ hội trải nghiệm
            những hoạt động mang tính tương tác cao, dưới sự hỗ trợ của giáo
            viên như trao đổi, thảo luận, làm dự án theo nhóm, v.v. Qua đó, việc
            học trực tuyến sẽ trở nên thú vị và hiệu quả hơn.
          </p>
          <p className="font-svn-medium font-size-24 font-weight-700 text-center color-4754e2 mb-4">
            Tại sao bạn nên xây dựng một khoá học cohort?
          </p>
          <div className="row">
            <div className="slick-slider slick-initialized">
              <div className="slick-list">
                <div
                  className="slick-track"
                  style={{
                    width: '1116px',
                    opacity: 1,
                    transform: 'translate3d(0px, 0px, 0px)',
                  }}
                >
                  <div
                    data-index="0"
                    className="slick-slide slick-active slick-current"
                    tabIndex="-1"
                    aria-hidden="false"
                    style={{ outline: 'none', width: '372px' }}
                  >
                    <div>
                      <div className="card-description-cohooz m-1 m-lg-3 h-100">
                        <div className="d-flex justify-content-center align-items-center mb-4 mb-lg-5">
                          <div
                            className="style-box-in-card-description-cohooz"
                            style={{ backgroundColor: 'rgb(0, 20, 102)' }}
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              color="#fff"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: 'rgb(255, 255, 255)' }}
                            >
                              <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="mb-5" style={{ height: '75px' }}>
                          <p className="style-text-SVN-Regular font-size-18 font-weight-700 text-center mb-0">
                            Khoá học cohort mang lại tiềm năng thu nhập lớn cho
                            bạn
                          </p>
                        </div>
                        <div className="" style={{ height: '110px' }}>
                          <p className="style-text-SVN-Regular font-size-16 font-weight-400 text-center mb-2">
                            Với một khoá học cohort, bạn có thể gia tăng nguồn
                            thu nhập một cách nhanh chóng, đồng thời đem lại
                            nhiều giá trị cho cộng đồng.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index="1"
                    className="slick-slide slick-active"
                    tabIndex="-1"
                    aria-hidden="false"
                    style={{ outline: 'none', width: '372px' }}
                  >
                    <div>
                      <div className="card-description-cohooz m-1 m-lg-3 h-100">
                        <div className="d-flex justify-content-center align-items-center mb-4 mb-lg-5">
                          <div
                            className="style-box-in-card-description-cohooz"
                            style={{ backgroundColor: 'rgb(255, 119, 87)' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="#fff"
                            >
                              <path d="M21,4H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V4H3A1,1,0,0,0,2,5V8a4,4,0,0,0,4,4H7.54A6,6,0,0,0,11,13.91V16H10a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V19a3,3,0,0,0-3-3H13V13.91A6,6,0,0,0,16.46,12H18a4,4,0,0,0,4-4V5A1,1,0,0,0,21,4ZM6,10A2,2,0,0,1,4,8V6H6V8a6,6,0,0,0,.35,2Zm8,8a1,1,0,0,1,1,1v1H9V19a1,1,0,0,1,1-1ZM16,8A4,4,0,0,1,8,8V4h8Zm4,0a2,2,0,0,1-2,2h-.35A6,6,0,0,0,18,8V6h2Z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="mb-5" style={{ height: '75px' }}>
                          <p className="style-text-SVN-Regular font-size-18 font-weight-700 text-center mb-0">
                            Khoá học cohort giúp bạn xây dựng thương hiệu cá
                            nhân
                          </p>
                        </div>
                        <div className="" style={{ height: '110px' }}>
                          <p className="style-text-SVN-Regular font-size-16 font-weight-400 text-center mb-2">
                            Qua việc truyền tải kiến thức cũng như kinh nghiệm
                            “thực chiến”, bạn có thể định vị bản thân ở tầm
                            “chuyên gia" và nâng cao uy tín trong ngành
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index="2"
                    className="slick-slide slick-active"
                    tabIndex="-1"
                    aria-hidden="false"
                    style={{ outline: 'none', width: '372px' }}
                  >
                    <div>
                      <div className="card-description-cohooz m-1 m-lg-3 h-100">
                        <div className="d-flex justify-content-center align-items-center mb-4 mb-lg-5">
                          <div
                            className="style-box-in-card-description-cohooz"
                            style={{ backgroundColor: 'rgb(253, 184, 112)' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="#fff"
                            >
                              <path d="M21,8a2,2,0,1,0-2.27,2,4.49,4.49,0,0,1-3,5.85,3,3,0,0,0-1.3-1.43,7,7,0,1,0-10.9,0A3,3,0,0,0,2,17v1a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3v-.19a6.47,6.47,0,0,0,4.58-8.59A2,2,0,0,0,21,8ZM14,18a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V17a1,1,0,0,1,1-1h.41a6.94,6.94,0,0,0,7.18,0H13a1,1,0,0,1,1,1ZM9,15a5,5,0,1,1,5-5A5,5,0,0,1,9,15ZM9,9a1,1,0,1,0,1,1A1,1,0,0,0,9,9Z"></path>
                            </svg>
                          </div>
                        </div>
                        <div className="mb-5" style={{ height: '75px' }}>
                          <p className="style-text-SVN-Regular font-size-18 font-weight-700 text-center mb-0">
                            Khoá học cohort đem đến trải nghiệm học trực tuyến
                            tốt hơn cho cộng đồng học viên của bạn.
                          </p>
                        </div>
                        <div className="" style={{ height: '110px' }}>
                          <p className="style-text-SVN-Regular font-size-16 font-weight-400 text-center mb-2">
                            Cơ hội học hỏi, tương tác, và mở rộng mạng lưới mối
                            quan hệ - đây là những gì mà học viên của bạn sẽ
                            nhận được khi tham gia một khoá học cohort.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
