import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'status',
    headerName: 'Trạng thái',
    width: 300,
  },
]

const rows = [
  {
    id: 1,
    lastName: 'Sơn',
    firstName: 'Nguyễn Xuân',
    email: 'nguyennhuquynh@gmail.com',
    age: 35,
    status: 'Đã thanh toán',
  },
  {
    id: 2,
    lastName: 'Kiên',
    firstName: 'Nguyễn Trung',
    email: 'nguyennhuquynh@gmail.com',
    age: 24,
    status: 'Đã thanh toán',
  },
  {
    id: 3,
    lastName: 'Hằng',
    firstName: 'Trần Thị',
    email: 'nguyennhuquynh@gmail.com',
    age: 18,
    status: 'Đã thanh toán',
  },
  {
    id: 4,
    lastName: 'Lâm',
    firstName: 'Nguyễn Vũ',
    email: 'nguyennhuquynh@gmail.com',
    age: 34,
    status: 'Đã thanh toán',
  },
  {
    id: 5,
    lastName: 'Quỳnh',
    firstName: 'Hoàng Như',
    email: 'nguyennhuquynh@gmail.com',
    age: 14,
    status: 'Đã thanh toán',
  },
  {
    id: 6,
    lastName: 'Như',
    firstName: 'Vân',
    email: 'nguyennhuquynh@gmail.com',
    age: 23,
    status: 'Đã thanh toán',
  },
  {
    id: 7,
    lastName: 'Phương',
    firstName: 'Hoàng',
    email: 'nguyennhuquynh@gmail.com',
    age: 26,
    status: 'Đã thanh toán',
  },
  {
    id: 8,
    lastName: 'Mikah Dang',
    firstName: 'Nguyễn Thị',
    email: 'nguyennhuquynh@gmail.com',
    age: 23,
    status: 'Đã thanh toán',
  },
  {
    id: 9,
    lastName: 'Nhung',
    firstName: 'Nguyễn Thị ',
    email: 'nguyennhuquynh@gmail.com',
    age: 65,
    status: 'Đã thanh toán',
  },
]
const Students = () => {
  const [currenttab, setCurrenttab] = React.useState(0)
  const notActive = 'mr-6 cursor-pointer pb-3 '
  const Active =
    'cursor-pointer pb-3 border-b-4 border-primary-300 text-gray-900 transition ease-out duration-200 mr-6'
  return (
    <div className="-ml-4  grow overflow-hidden rounded-tl-3xl rounded-bl-3xl border-l bg-white shadow-md">
      <div className="undefined">
        <div className="prose-product-sm flex h-full flex-col sm:prose-product-lg">
          <div className="z-10 flex h-20 items-center justify-between py-2 px-8">
            <div className="grow">
              <div className="mr-6 flex flex-auto justify-between">
                <div className="flex items-center">
                  <div className="mr-6 font-graphik text-xl font-bold">
                    Học viên
                  </div>
                  <div className="mr-2"></div>
                  <div className="">
                    <div className="relative rounded-lg border border-gray-400 bg-white text-gray-700 shadow-sm ">
                      <select
                        data-cy="cohort-dropdown"
                        className="py-2 pl-4 pr-12 relative z-10 cursor-pointer appearance-none bg-transparent  "
                      >
                        <option value="2376">Cohort 1 </option>
                        <option value="waitlist">Danh sách chờ</option>
                      </select>
                      <div className="absolute right-4 top-0 bottom-0 flex items-center">
                        <svg
                          className="text-gray-700"
                          width="20"
                          height="20"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 6L8 11L3 6"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="ml-4 rounded-5xl px-4 py-2 transition duration-200 ease-out bg-white">
                    <button data-cy="student-filter-button">
                      <svg
                        className="inline"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.5999 7.25C2.5999 4.68188 4.68177 2.6 7.2499 2.6C9.81802 2.6 11.8999 4.68188 11.8999 7.25C11.8999 8.53251 11.3807 9.69376 10.541 10.535C10.5399 10.5361 10.5388 10.5372 10.5377 10.5383C10.5366 10.5393 10.5356 10.5404 10.5345 10.5415C9.69328 11.381 8.5322 11.9 7.2499 11.9C4.68177 11.9 2.5999 9.81812 2.5999 7.25ZM10.9403 11.7894C9.93358 12.6088 8.64911 13.1 7.2499 13.1C4.01903 13.1 1.3999 10.4809 1.3999 7.25C1.3999 4.01914 4.01903 1.4 7.2499 1.4C10.4808 1.4 13.0999 4.01914 13.0999 7.25C13.0999 8.64944 12.6085 9.93412 11.7888 10.9409L14.4238 13.5758C14.6581 13.8101 14.6581 14.19 14.4238 14.4243C14.1895 14.6586 13.8096 14.6586 13.5752 14.4243L10.9403 11.7894Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex items-center"></div>
              </div>
            </div>
            <div className="relative inline-block text-left">
              <button
                className="flex items-center justify-items-center rounded-full border border-gray-200 py-2 px-3 transition duration-200 ease-out hover:border-gray-300 hover:bg-gray-100 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <svg className="mt-1 h-6 w-6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.00004 7.8335C9.9283 7.8335 10.8185 7.46475 11.4749 6.80837C12.1313 6.15199 12.5 5.26175 12.5 4.3335C12.5 3.40524 12.1313 2.515 11.4749 1.85862C10.8185 1.20225 9.9283 0.833496 9.00004 0.833496C8.07178 0.833496 7.18154 1.20225 6.52517 1.85862C5.86879 2.515 5.50004 3.40524 5.50004 4.3335C5.50004 5.26175 5.86879 6.15199 6.52517 6.80837C7.18154 7.46475 8.07178 7.8335 9.00004 7.8335ZM0.833374 18.3335C0.833374 17.261 1.04461 16.1991 1.45502 15.2082C1.86544 14.2174 2.46699 13.3171 3.22534 12.5588C3.98368 11.8004 4.88397 11.1989 5.87479 10.7885C6.86562 10.3781 7.92758 10.1668 9.00004 10.1668C10.0725 10.1668 11.1345 10.3781 12.1253 10.7885C13.1161 11.1989 14.0164 11.8004 14.7747 12.5588C15.5331 13.3171 16.1346 14.2174 16.5451 15.2082C16.9555 16.1991 17.1667 17.261 17.1667 18.3335H0.833374Z"
                    fill="#555555"
                  ></path>
                </svg>
                <svg className="h-6 w-6 border-gray-400">
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="border-t"></div>
          <div className="flex justify-between border-b">
            <div className="mt-4 flex pl-8 text-xs font-medium uppercase tracking-wider text-gray-700 ">
              {['Đã hủy (0)', `Đã thanh toán (${rows.length})`].map(
                (item, i) => (
                  <div
                    data-cy="enrolled-pointer"
                    className={currenttab === i ? Active : notActive}
                    onClick={() => setCurrenttab(i)}
                  >
                    {item}
                  </div>
                ),
              )}
            </div>
            <div className="my-2 mr-4 flex justify-end px-8">
              <button
                data-cy="add-user-button"
                className="flex items-center text-sm font-medium"
              >
                <svg
                  className="mr-1 inline w-4"
                  width="32"
                  height="32"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 8H13.5"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8 2.5V13.5"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>{' '}
                Thêm học viên
              </button>
              <div className="mx-3"></div>
              <button data-cy="download-student-list">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.0625 10.3135L12 14.2499L15.9375 10.3135"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M12 3.75V14.2472"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25"
                    stroke="currentColor"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            className="-mr-20 flex flex-col overflow-y-scroll pr-20"
            style={{ height: 'calc(100vh - 80px)' }}
          >
            <div className="flex px-6">
              <div className="grow">
                <div
                  className="relative overflow-scroll"
                  style={{ height: 'calc(100vh - 160px)', width: '100%' }}
                >
                  {currenttab === 1 ? (
                    <DataGrid
                      sx={{
                        my: 2,
                      }}
                      rows={rows}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      autoHeight
                    />
                  ) : (
                    <div className="flex flex-col items-center py-10">
                      <div className="flex h-16 w-16 rounded-full bg-gray-200">
                        <svg
                          className="m-auto"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16Z"
                            fill="#707070"
                          ></path>
                          <path
                            d="M19.5 16C20.3284 16 21 15.3284 21 14.5C21 13.6716 20.3284 13 19.5 13C18.6716 13 18 13.6716 18 14.5C18 15.3284 18.6716 16 19.5 16Z"
                            fill="#707070"
                          ></path>
                          <path
                            d="M27 27L23.3333 24L19.6667 27L16 24L12.3333 27L8.66667 24L5 27V15C5 12.0826 6.15893 9.28473 8.22183 7.22183C10.2847 5.15893 13.0826 4 16 4C18.9174 4 21.7153 5.15893 23.7782 7.22183C25.8411 9.28473 27 12.0826 27 15V27Z"
                            stroke="#707070"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <span className="mt-4 text-gray-700">
                        Chưa có học viên tại đây...
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Students
