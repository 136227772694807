import React from 'react'

const Question_Mentions = () => {
  return (
    <div className="container">
      <div id="faq" className="style-layout-course-body">
        <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 color-4754e2 mb-3 mb-md-5">
          Câu hỏi thường gặp
        </p>
        <div className="pt-3 mb-4">
          <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700 mb-3">
            1. Sau khi hoàn thành khóa học, tôi có thể ứng tuyển vào những vị
            trí nào?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400">
            Nắm vững kiến thức nền tảng về JavaScript sẽ giúp bạn có cơ hội ứng
            tuyển vào các vị trí khác nhau như Back-end developer, Front-end
            developer, Full-stack developer, Android Developer, iOS Developer và
            rất nhiều những vị trí hấp dẫn khác trong lĩnh vực Công nghệ thông
            tin.
          </p>
        </div>
        <div className="pt-3 mb-4">
          <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700 mb-3">
            2. Em chưa có hiểu biết cơ bản về lập trình thì có tham gia khóa học
            này được không ạ?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400">
            Bạn hoàn toàn có thể tham gia khóa học mà không cần kiến thức đầu
            vào về lập trình, bởi giảng viên sẽ giúp bạn tìm hiểu từ những thuật
            ngữ cơ bản nhất như biến, kiểu dữ liệu, chuỗi, mảng, vòng lặp, v.v
            và hỗ trợ bạn những hành trang cần thiết nhất cả về mặt thái độ, tâm
            lý để bạn có thể tự tin chinh phục thế giới công nghệ.
          </p>
        </div>
        <div className="pt-3 mb-4">
          <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700 mb-3">
            3. Đâu là điểm khác biệt của khóa học JavaScript tại Boost?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400">
            Đến với khóa học JavaScript Hero, bạn sẽ được trải nghiệm một hình
            thức học tập trực tuyến kiểu mới, trong đó các hoạt động tương tác
            giữa giảng viên và học viên sẽ vô cùng đa dạng, như các dự án nhóm,
            phiên thảo luận, mini hackathon (cuộc thi phát triển phần mềm). Đặc
            biệt, với nền tảng tích hợp của Boost, bạn có thể tìm thấy mọi thứ
            bạn cần như lịch học, tài liệu bổ trợ, nhóm lớp, v.v. giúp việc học
            trực tuyến trở nên hiệu quả hơn bao giờ hết.
          </p>
        </div>
        <div className="pt-3 mb-4">
          <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700 mb-3">
            4. Nếu tôi bận và lỡ mất một buổi học, tôi có thể xem lại bài giảng
            được không?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400">
            Boost sẽ hỗ trợ ghi lại bài giảng, vậy nên bạn có thể xem lại vào
            bất cứ lúc nào. Tuy nhiên, bạn vẫn nên sắp xếp thời gian tham gia
            đầy đủ các buổi học để có cơ hội tương tác với giảng viên và những
            học viên khác. Đây chính là điểm nhấn của các khóa học trên nền tảng
            Boost.
          </p>
        </div>
        <div className="pt-3 mb-4">
          <p className="font-svn-medium font-size-24 font-size-mb-18 font-weight-700 mb-3">
            5. Nếu có thắc mắc, tôi có thể trực tiếp liên hệ với giáo viên hay
            không?
          </p>
          <p className="font-svn font-size-18 font-size-mb-14 font-weight-400">
            Bạn có thể liên hệ với giảng viên trong suốt quá trình diễn ra khóa
            học cũng như sau khóa học thông qua email và các kênh mạng xã hội.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Question_Mentions
