import React from 'react'
import Header from '../../../components/header'
import About from './about'
import Partner from './partner'
import Slideshow from './slideshow'
import Title from './title'
import Review from './review'
import Footer from '../../../components/footer'
import { IMAGES } from '../../../constants/images'

const Body = ({ refS, context }) => {
  const [acitiveModal, setAcitiveModal, currentID, setCurrentId] = context
  const { titleRef, courseRef, contactRef } = refS
  return (
    <div>
      <Header
        contactRef={contactRef}
        courseRef={courseRef}
        titleRef={titleRef}
        context={{ acitiveModal, setAcitiveModal }}
      />
      <div className="">
        <div className="container pr-lg-0 pl-lg-0">
          <div className="position-relative py-3 py-md-5">
            <div className="z-index-100 row">
              <div className="d-block d-md-none mb-4 mb-md-0 col-sm-12">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img
                    src={IMAGES.IMG_2}
                    alt="Homeslider"
                    style={{ height: '100%', width: '100%' }}
                  />
                </div>
              </div>
              <Title />
              <div className="mt-5 mt-lg-0 d-none d-md-block col-lg-4">
                <div className="d-flex justify-content-center align-items-center h-100">
                  <img src={IMAGES.IMG_3} alt="Homeslider" />
                </div>
              </div>
            </div>
            <div
              className="position-absolute z-index-absolute h-100 d-none d-md-block"
              style={{ top: '0px', right: '10%' }}
            >
              <img
                src={IMAGES.IMG_4}
                alt="Homeslider"
                style={{ height: '100%' }}
              />
            </div>
          </div>
        </div>
        <Slideshow
          courseRef={courseRef}
          modal={[currentID, setCurrentId]}
          context={[acitiveModal, setAcitiveModal]}
        />
        <div className="bg-f2">
          <div className="container">
            <div className="py-5">
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-md-9">
                  <p className="font-svn-medium font-weight-700 font-size-36 font-size-mb-24 text-center">
                    Tại sao
                    <span className="font-svn-medium font-size-36 font-size-mb-24 font-style-italic font-weight-700 color-4754e2">
                      {''} giảng viên {''}
                    </span>
                    nên hợp tác với chúng tôi?
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="cohooz-1000 col-3 col-md-1"></div>
              </div>
              <div className="mt-5 d-none d-md-block">
                <div className="lazyload-wrapper">
                  <div
                    className="lazyload-placeholder items-center"
                    style={{ height: '500px' }}
                  >
                    <img
                      src={IMAGES.IMG_1}
                      alt="cooperate"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="home-learning">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-md-7">
                <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 text-center">
                  Tại sao
                  <span className="font-svn-medium font-size-36 font-style-italic font-size-mb-24 font-weight-700 color-4754e2">
                    {''} học viên {''}
                  </span>
                  nên lựa chọn khóa học trên nền tảng của chúng tôi?
                </p>
              </div>
            </div>
            <div className="position-relative">
              <figure className="learning-image">
                <img
                  src={IMAGES.IMG_5}
                  alt="Wellerly"
                  className="w-100 d-none d-sm-block"
                />
              </figure>
              <div className="learning-text">
                <div className="row">
                  <div className="col-sm-6 col-12 offset-sm-6">
                    <div className="benefit-item item-1">
                      <figure className="learning-image">
                        <div className="lazyload-wrapper">
                          <div className="lazyload-placeholder"></div>
                        </div>
                      </figure>
                      <h5 className="benefit-title">
                        Trải nghiệm học tập xuyên suốt
                      </h5>
                      <p className="benefit-description">
                        Cơ hội tiếp cận mọi công cụ và tài nguyên cần thiết giúp
                        việc học trực tuyến trở nên đơn giản và hiệu quả
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="benefit-item item-2">
                      <figure className="learning-image">
                        <div className="lazyload-wrapper">
                          <div className="lazyload-placeholder"></div>
                        </div>
                      </figure>
                      <h5 className="benefit-title">
                        Lựa chọn khóa học đa dạng
                      </h5>
                      <p className="benefit-description">
                        Cơ hội tham gia hàng ngàn khoá học trực tuyến thuộc mọi
                        lĩnh vực từ các chuyên gia hàng đầu
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12 offset-sm-6">
                    <div className="benefit-item item-3">
                      <figure className="learning-image">
                        <div className="lazyload-wrapper">
                          <div className="lazyload-placeholder"></div>
                        </div>
                      </figure>
                      <h5 className="benefit-title">
                        Môi trường học tập rộng mở
                      </h5>
                      <p className="benefit-description">
                        Cơ hội tương tác và giao lưu cùng cộng đồng học tập trực
                        tuyến đang ngày càng lớn mạnh
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Partner />
        <div>
          <div className="position-relative">
            <div className="position-absolute h-100 w-100">
              <img
                src={IMAGES.IMG_6}
                alt=""
                className=""
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
        </div>
        <About titleRef={titleRef} />
        <Review />
        <Footer contactRef={contactRef} />
      </div>
    </div>
  )
}

export default Body
