import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../../context/global'
const data = require('../../../dummyData/course.json')

const RoomInfo = {
  Id: '848 5446 4825',
  Pass: 'y9mHa3',
}
const Modal = ({ context, modal, hideContext }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const globalContext = React.useContext(GlobalContext)
  const { courses, setCourses } = globalContext
  const [currentID, setCurrentId] = modal
  const [acitiveModal, setAcitiveModal] = context
  const findMyCourse = data.find((m) => m.id === currentID)
  const toogleInput = () => {
    setAcitiveModal(!acitiveModal)
  }
  const AddToMyCourse = () => {
    setAcitiveModal(!acitiveModal)
    const findId = courses.find((m) => m.id === currentID)
    if (!findId) {
      setCourses((prev) => [
        ...prev,
        { ...findMyCourse, tab: 'tab1', value: 2 },
      ])
    }
    if (Object.keys(hideContext).length > 0) {
      const { open, setOpen } = hideContext
      setOpen(!open)
    }
  }

  const PayMyCourse = () => {
    if (currentID) {
      navigate(`/payment-form/${currentID}`)
    }
  }
  return (
    <div>
      <input
        class="modal-btn"
        type="checkbox"
        id="modal-btn"
        name="modal-btn"
        style={{ display: 'none' }}
      />
      <div
        className="modal"
        style={{
          display: acitiveModal ? 'block' : 'none',
          opacity: acitiveModal ? 1 : 0,
        }}
      >
        <div className="fixed top-0 left-0 z-30 h-screen w-full">
          <div className="absolute top-0 left-0 h-full w-full bg-black/40"></div>
          <div className="fixed top-0 left-0 right-0 sm:mx-4">
            <div className="fixed h-full w-full animate-slideup sm:static sm:animate-none sm:w-auto sm:h-auto sm:max-w-120 sm:mx-auto sm:rounded-xl md:max-w-240 sm:mt-12 bg-white shadow flex flex-col max-h-screen sm:max-h-screen-80px overflow-y-scroll">
              <div className="sticky top-0 z-50 bg-white">
                <div className="flex justify-between border-b">
                  <div className="flex flex-auto items-center px-4 py-5 sm:px-12">
                    <div className="text-left font-graphik text-lg font-medium fw-bold">
                      {findMyCourse.title}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <button
                      onClick={toogleInput}
                      className="m-4 text-xl text-type-medium sm:text-base"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {!findMyCourse.isUpdate ? (
                <>
                  <div className="px-4 pb-4 sm:px-12 sm:pb-12">
                    <div className="prose-sm mt-6 md:prose-md">
                      <div className="md:hidden">
                        <div className="aspect-w-16 aspect-h-9 relative overflow-hidden rounded-lg">
                          <span
                            style={{
                              boxSizing: 'border-box',
                              display: 'block',
                              overflow: 'hidden',
                              width: 'initial',
                              height: 'initial',
                              background: 'none',
                              opacity: 1,
                              border: '0px',
                              margin: '0px',
                              padding: '0px',
                              position: 'absolute',
                              inset: '0px',
                            }}
                          >
                            <img
                              sizes="100vw"
                              srcSet="
                            /_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=415&amp;q=75   415w,
                            /_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=768&amp;q=75   768w,
                            /_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=1024&amp;q=75 1024w,
                            /_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=1280&amp;q=75 1280w,
                            /_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=1536&amp;q=75 1536w
                          "
                              src="/_next/image?url=https%3A%2F%2Fd2426xcxuh3ht5.cloudfront.net%2Fclp-images%2Fpomps-crypto-course.jpg&amp;w=1536&amp;q=75"
                              decoding="async"
                              data-nimg="fill"
                              className="undefined duration-300 ease-in-out"
                              style={{
                                position: 'absolute',
                                inset: '0px',
                                boxSizing: 'border-box',
                                padding: '0px',
                                border: 'none',
                                margin: 'auto',
                                display: 'block',
                                width: '0px',
                                height: '0px',
                                winWidth: '100%',
                                maxWidth: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </span>
                        </div>
                        <div className="space-y-6">
                          <div>
                            <div className="l1 mb-2 uppercase text-gray-700">
                              Bạn sẽ học được gì từ khóa học
                            </div>
                            <div className="p2-tall text-gray-800">
                              {findMyCourse.desc}
                            </div>
                          </div>
                          <div className="space-y-4">
                            <div className="l1 uppercase text-gray-700">
                              Tác giả
                            </div>
                            <div className="space-y-2">
                              <div className="p2 font-semibold text-gray-800">
                                {findMyCourse.desc}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:flex md:flex-row md:justify-between">
                        <div className="w-3/5 space-y-6">
                          <div>
                            <div className="l1 uppercase text-black-800 fw-bold mb-2">
                              Bạn sẽ học được gì?
                            </div>
                            <div className="p2-tall text-gray-800">
                              {findMyCourse.desc}
                            </div>
                          </div>
                          <div className="space-y-4">
                            <div className="l1 uppercase text-black-800 fw-bold">
                              Giới thiệu về giảng viên
                            </div>
                            <div className="space-y-2">
                              <div className="p2-tall text-gray-800">
                                {findMyCourse.descAuthor}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sticky top-20 h-min-content w-7/20">
                          <div className="space-y-8 bg-white md:rounded-lg md:border md:border-gray-300 md:p-6">
                            <div>
                              <div className="flex items-center justify-between">
                                <div className="p1 font-semibold">
                                  <p className="d-flex flex-row items-center">
                                    <span className="fw-bold text-black">
                                      Giá:
                                    </span>
                                    <div className="d-flex flex-row items-center ml-1 ">
                                      {findMyCourse.price}
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="space-y-4 md:space-y-3">
                              <div className="space-y-2">
                                <div className="d-flex flex-row items-center">
                                  <p className="p3 font-semibold">
                                    Sắp diễn ra:{' '}
                                  </p>
                                  <p className="p3 ml-1">Tháng 1, 2023</p>
                                </div>
                                <div className="d-flex flex-row items-center">
                                  <p className="p3 font-semibold">
                                    Hạn đăng ký:{' '}
                                  </p>
                                  <p className="p3 ml-1">Tháng 12, 2022</p>
                                </div>
                                <div className="prose-product-sm sm:prose-product-lg">
                                  {location.pathname === '/' && (
                                    <div>
                                      <button
                                        data-cy=""
                                        className="justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default w-full h-12 px-6 b2 rounded-.5xl bg-primary text-white hover:bg-primary-200 disabled:bg-gray-500"
                                        type="button"
                                        onClick={AddToMyCourse}
                                      >
                                        Tham gia khóa học
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {location.pathname === '/mycourse' && (
                                <div className="space-y-2">
                                  <div className="d-flex flex-row items-center">
                                    <p className="p3 font-semibold">
                                      My class Zoom ID:{' '}
                                    </p>
                                    <p className="p3 ml-1">{RoomInfo.Id}</p>
                                  </div>
                                  <div className="d-flex flex-row items-center">
                                    <p className="p3 font-semibold">
                                      Password :{' '}
                                    </p>
                                    <p className="p3 ml-1">{RoomInfo.Pass}</p>
                                  </div>
                                  <div className="d-flex flex-row items-center">
                                    <p className="p3 font-semibold">
                                      Trạng thái:{' '}
                                    </p>
                                    <p className="p3 ml-1">Chưa thanh toán</p>
                                  </div>
                                </div>
                              )}
                              {location.pathname === '/' && (
                                <div className="space-y-2 mt-3">
                                  <p className="p3 text-gray-900">
                                    Hãy để lại email của bạn
                                  </p>
                                  <div className="h-14 w-full sm:max-w-100">
                                    <div className="flex bg-white border shadow-sm hover:shadow-md rounded-2xl p-1 font-graphik transition ease-out duration-200 h-full items-center border-gray-300 focus-within:border-gray-900 hover:border-gray-400">
                                      <input
                                        className=" disabled:bg-white border-none w-full shrink grow-0 text-gray-600 placeholder-gray-600 focus:text-gray-900 focus:outline-none p3 px-1 sm:p2 sm:px-2"
                                        type="email"
                                        placeholder="Your email"
                                        value=""
                                        style={{
                                          lineHeight: '1.125rem',
                                          alignSelf: 'flex-start',
                                        }}
                                      />
                                      <button
                                        aria-label="submit"
                                        className="bg-primary shrink-0 rounded-xl text-white h-10"
                                      >
                                        <span className="flex items-center px-6">
                                          <svg
                                            className="shrink-0"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M7.67155 1.33333L14.6667 8L7.67155 14.6667L6.93842 13.9206L12.606 8.5191H1.33333V7.48089H12.606L6.93842 2.07939L7.67155 1.33333Z"
                                              fill="currentColor"
                                            ></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {location.pathname === '/mycourse' && (
                                <div>
                                  <button
                                    data-cy=""
                                    className="justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default w-full h-12 px-6 b2 rounded-.5xl bg-primary text-white hover:bg-primary-200 disabled:bg-gray-500"
                                    type="button"
                                    onClick={PayMyCourse}
                                  >
                                    Thanh toán
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sticky bottom-0 z-50 mt-auto border-t border-gray-300 bg-white py-5 sm:border-t-2">
                    <div className="prose-sm md:prose-md">
                      <div className="hidden text-center md:block">
                        <Link to="/detail-course">
                          <button className="b1 text-center underline">
                            Xem chi tiết khóa học
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="px-5 items-center text-center  pb-4 sm:px-12 sm:pb-12 "
                  style={{
                    height: '300px',
                  }}
                >
                  <span className="text-black fw-bold fs-italic">
                    {' '}
                    Đang cập nhật
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
