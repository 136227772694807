import React from 'react'
import { useNavigate } from 'react-router-dom'
require('@solana/wallet-adapter-react-ui/styles.css')

const logo = require('../../static/image/logo/logo.png')
const LoginButton1 = () => {
  const navigate = useNavigate()
  return (
    <div id="islogin1">
      <div className="d-flex">
        <button
          onClick={() => navigate('/login')}
          className="btn btn-dang-nhap mr-2"
        >
          ĐĂNG NHẬP
        </button>

        <button
          onClick={() => navigate('/teacherForm')}
          className="btn bg-primary btn-hop-tac text-uppercase"
        >
          Hợp Tác Làm Giảng Viên
        </button>
      </div>
    </div>
  )
}
const LoginButtonMobile1 = () => {
  const navigate = useNavigate()
  return (
    <div id="islogin1">
      <a className="text-left" href="/#khoa-hoc">
        <button
          onClick={() => navigate('/login')}
          className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black"
        >
          Đăng nhập
        </button>
      </a>
      <a
        onClick={() => navigate('/teacherForm')}
        className="text-left"
        href="/#khoa-hoc"
      >
        <button className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black">
          Hợp Tác Làm Giảng Viên
        </button>
      </a>
    </div>
  )
}
const LoginButton2 = ({ context }) => {
  const [activeLogin, setactiveLogin] = context
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem('users')
    setactiveLogin(false)
    navigate('/')
  }
  return (
    <div id="islogin2">
      <div className="d-flex">
        <button
          onClick={() => navigate('/navigate-teacher-student')}
          type="button"
          className="btn btn-dang-nhap mr-2"
        >
          KHÓA HỌC CỦA TÔI
        </button>

        <button
          onClick={logout}
          type="button"
          className="btn bg-primary btn-hop-tac text-uppercase"
        >
          ĐĂNG XUẤT
        </button>
      </div>
    </div>
  )
}
const LoginButtonMobile2 = ({ context }) => {
  const [activeLogin, setactiveLogin] = context
  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem('users')
    setactiveLogin(false)
    navigate('/')
  }
  return (
    <div id="islogin2">
      <a className="text-left" href="/#khoa-hoc">
        <button
          onClick={() => navigate('/navigate-teacher-student')}
          className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black"
        >
          Khóa học của tôi
        </button>
      </a>
      <a onClick={logout} className="text-left" href="/#khoa-hoc">
        <button className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black">
          Đăng xuất
        </button>
      </a>
    </div>
  )
}
const Header = ({ context, titleRef, courseRef, contactRef }) => {
  const navigate = useNavigate()
  const { acitiveModal, setAcitiveModal } = context
  const [activeLogin, setactiveLogin] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768
  React.useEffect(() => {
    const checkLogin = () => {
      const fetchUser = localStorage.getItem('users')
      setactiveLogin(fetchUser ? true : false)
    }
    checkLogin()
  }, [])
  const stylev = isMobile
    ? `d-flex flex-row align-items-center h-100 w-full justify-between`
    : `row align-items-center h-100 items-center`
  return (
    <div
      className="sticky-top items-center"
      style={{ display: acitiveModal ? 'none' : 'block' }}
    >
      <div className="bg-white">
        <div className="container">
          <div className="style-header-cohooz">
            <div className={stylev}>
              <nav className="navbar navbar-expand-lg d-flex navbar-light p-0">
                <div
                  onClick={() => navigate('/')}
                  className="mr-auto d-flex justify-content-center align-items-center h-100 cursor-pointer"
                >
                  <img className="h-15 w-100" src={logo} alt="B" />
                </div>
                {!isMobile && (
                  <div className="d-'none' d-sm-'none' d-lg-block">
                    <div className="d-flex">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="navbar-toggler-pc mr-auto p-2">
                          <ul className="navbar-nav mr-auto">
                            <li className="nav-item mr-4 py-2 handle-hover-4754e2">
                              <p
                                onClick={() =>
                                  titleRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                  })
                                }
                                className="text-header-nav mb-0 cursor-pointer undefined"
                              >
                                VỀ BOOST
                              </p>
                            </li>
                            <li className="nav-item mr-4 py-2 handle-hover-4754e2">
                              <p
                                onClick={() =>
                                  courseRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                  })
                                }
                                className="text-header-nav cursor-pointer mb-0 undefined"
                              >
                                KHOÁ HỌC
                              </p>
                            </li>
                            <li className="nav-item mr-4 py-2 handle-hover-4754e2">
                              <p
                                onClick={() =>
                                  contactRef.current.scrollIntoView({
                                    behavior: 'smooth',
                                  })
                                }
                                className="text-header-nav cursor-pointer mb-0 undefined"
                              >
                                LIÊN HỆ
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {!activeLogin ? (
                        <LoginButton1 />
                      ) : (
                        <LoginButton2 context={[activeLogin, setactiveLogin]} />
                      )}
                    </div>
                  </div>
                )}
              </nav>
              {isMobile && (
                <div className="d-sm-block" onClick={() => setOpen(!open)}>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="50"
                    width="50"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </div>
              )}
              {open && <div className="backDrop"></div>}
              {open && (
                <nav
                  className="side-drawer d-sm-block"
                  style={{
                    display: isMobile ? 'block' : 'none',
                  }}
                >
                  <div className="">
                    <button
                      type="button"
                      className="opacity-close close btn-block text-right"
                      aria-label="Close"
                    >
                      <span
                        onClick={() => setOpen(!open)}
                        aria-hidden="true"
                        style={{ fontSize: '40px' }}
                      >
                        ×
                      </span>
                    </button>
                    <div className="ml-4 pt-5">
                      <nav className="navbar-nav justify-content-center d-flex w-100 pt-5">
                        <a className="text-left" href="/#cohooz">
                          <button className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px">
                            VỀ COHOOZ
                          </button>
                        </a>
                        <a className="text-left" href="/#khoa-hoc">
                          <button className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black">
                            KHOÁ HỌC
                          </button>
                        </a>
                        <a className="text-left mb-5" href="/#lien-he">
                          <button className="btn my-2 style-text-SVN-Regular font-size-14 font-weight-700 line-height-16px text-black">
                            LIÊN HỆ
                          </button>
                        </a>
                        <div className="navbar-nav justify-content-center d-flex w-100 ">
                          {!activeLogin ? (
                            <LoginButtonMobile1 />
                          ) : (
                            <LoginButtonMobile2
                              context={[activeLogin, setactiveLogin]}
                            />
                          )}
                        </div>
                      </nav>
                    </div>
                  </div>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
