import React from 'react'
import Header from './header'
import '../../static/css/admin/a1eaa96254255751.css'
import '../../static/css/admin/style1.css'
import '../../static/css/admin/f8aee2f66a30e49b.css'

const Layout_With_Header = ({ children }) => {
  return (
    <div id="" data-reactroot="">
      <div></div>
      <div
        style={{
          position: 'fixed',
          zIndex: 9999,
          top: '16px',
          left: '16px',
          right: '16px',
          bottom: '16px',
          pointerEvents: 'none',
        }}
      ></div>
      <div className="flex h-full bg-gray-900">
        <Header />
        {children}
      </div>
    </div>
  )
}

export default Layout_With_Header
