import React from 'react'
import Footer from '../../components/footer'
import Header_Detail_Course from '../../components/header/header-detail-course'
import Banner from './components/banner'
import Course_Payfee from './components/course-payfee'
import Course_Benefit from './components/course_benefit'
import Course_Button from './components/course_button'
import Course_Overview from './components/course_overview'
import List_Coures from './components/list_coures'
import Question_Mentions from './components/question_mentions'
import Quickview from './components/quickview'
import Student_Reponse from './components/student_reponse'
import Teacher_Info from './components/teacher_info'
import View_1 from './components/view_1'
import '../../static/css/detail-course.css'

const Detail_Course = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const [showScrollToTop, setShowScrollToTop] = React.useState(false)
  return (
    <div>
      <div className="course-javascript position-relative">
        <div
          onClick={scrollToTop}
          className="scroll-to-top cursor-pointer text-center"
        >
          <i className="icon fas fa-chevron-up" style={{ color: 'white' }}></i>
        </div>
        <div className="position-relative mb-0 course-javascript-header">
          <div className="course-javascript-header-text">
            <div className="container">
              <Header_Detail_Course />
              <Banner />
            </div>
          </div>
        </div>
        <Quickview />
        <View_1 />
        <Course_Overview />
        <Teacher_Info />
        <List_Coures />
        <Course_Benefit />
        {/* <Student_Reponse /> */}
        <Question_Mentions />
        <Course_Button />
        <Footer />
      </div>
    </div>
  )
}

export default Detail_Course
