import React from 'react'
import { useNavigate } from 'react-router-dom'

const Title = () => {
  const navigate = useNavigate()
  return (
    <div className="col-sm-12 col-lg-8">
      <div className="">
        <p className="font-svn-black font-size-56 font-size-mb-24 text-sm-center font-weight-900 line-height-126 mb-0">
          Học {''}
          <span className="font-svn-black font-size-56 font-size-mb-24 font-weight-900 color-4754e2">
            trực tuyến
          </span>
        </p>
        <p className="font-svn-black font-size-56 font-size-mb-24 text-sm-center font-weight-900 mb-4">
          hiệu quả như học {''}
          <span className="font-svn-black font-size-56 font-size-mb-24 font-weight-900 color-4754e2">
            trực tiếp
          </span>
        </p>
        <div className="row">
          <div className="d-flex justify-content-center align-items-center mb-4 col-sm-12 col-lg-9">
            <p className="font-svn font-size-18 font-size-mb-14 line-height-150 color-616161">
              Boost là một nền tảng giáo dục trực tuyến kiểu mới. Chúng tôi
              hướng đến việc cung cấp những khóa học chất lượng từ những chuyên
              gia hàng đầu cùng trải nghiệm học tập thú vị, giúp việc học trở
              nên dễ dàng và hiệu quả hơn.
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-10">
            <form className="">
              <div className="row">
                <div className="col-md-7 col-12 pr-md-2 mb-3 mb-lg-0">
                  <div className="">
                    <input
                      id="email"
                      type="email"
                      className="form-control style-input-login style-input-mail font-svn font-size-14 line-height-16 color-494949"
                      placeholder="Nhập vào kỹ năng bạn muốn học"
                      aria-label=""
                      value=""
                    />
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <button
                    className="btn bg-primary btn-hop-tac font-size-mb-12 w-100"
                    type="button"
                    onClick={() => navigate('studentForm')}
                  >
                    HỌC NGAY CÙNG BOOST
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Title
