import React from 'react'

const Email = () => {
  return (
    <div className="-ml-4 grow overflow-hidden rounded-tl-3xl rounded-bl-3xl border-l bg-white shadow-md">
      <div className="undefined">
        <div className="prose-product-sm sm:prose-product-lg">
          <div className="prose-product-sm flex h-full flex-col sm:prose-product-lg">
            <div className="z-10 flex h-20 items-center justify-between py-2 px-8">
              <div className="grow">
                <div className="mr-6 flex flex-auto justify-between">
                  <div className="flex items-center">
                    <div className="mr-6 font-graphik text-xl font-bold">
                      Emails
                    </div>
                    <div className="mr-2"></div>
                  </div>
                  <div className="flex items-center">
                    <div className="prose-product-sm sm:prose-product-lg">
                      <button
                        data-cy=""
                        className="justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-gray-900 text-white hover:bg-black disabled:bg-gray-500"
                        type="button"
                      >
                        <svg
                          className="mr-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.1407 2.24235L1.49519 5.52697C1.39722 5.5546 1.31002 5.61148 1.24524 5.69C1.18046 5.76852 1.14119 5.86494 1.13267 5.96638C1.12416 6.06782 1.14681 6.16943 1.1976 6.25765C1.24838 6.34587 1.32488 6.41649 1.41688 6.46007L6.76755 8.9946C6.87191 9.04403 6.95595 9.12806 7.00538 9.23242L9.53991 14.5831C9.58349 14.6751 9.6541 14.7516 9.74232 14.8024C9.83054 14.8532 9.93216 14.8758 10.0336 14.8673C10.135 14.8588 10.2315 14.8195 10.31 14.7547C10.3885 14.69 10.4454 14.6028 10.473 14.5048L13.7576 2.8593C13.7818 2.77376 13.7826 2.68333 13.7602 2.59733C13.7378 2.51133 13.6928 2.43287 13.63 2.37002C13.5671 2.30717 13.4886 2.26221 13.4026 2.23977C13.3166 2.21733 13.2262 2.21822 13.1407 2.24235Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M6.92892 9.07104L9.75735 6.24261"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        New email
                      </button>
                    </div>
                    <div className="ml-6 h-full border-l"></div>
                  </div>
                </div>
              </div>
              <div className="relative inline-block text-left">
                <button
                  className="flex items-center justify-items-center rounded-full border border-gray-200 py-2 px-3 transition duration-200 ease-out hover:border-gray-300 hover:bg-gray-100 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <svg className="mt-1 h-6 w-6">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.00004 7.8335C9.9283 7.8335 10.8185 7.46475 11.4749 6.80837C12.1313 6.15199 12.5 5.26175 12.5 4.3335C12.5 3.40524 12.1313 2.515 11.4749 1.85862C10.8185 1.20225 9.9283 0.833496 9.00004 0.833496C8.07178 0.833496 7.18154 1.20225 6.52517 1.85862C5.86879 2.515 5.50004 3.40524 5.50004 4.3335C5.50004 5.26175 5.86879 6.15199 6.52517 6.80837C7.18154 7.46475 8.07178 7.8335 9.00004 7.8335ZM0.833374 18.3335C0.833374 17.261 1.04461 16.1991 1.45502 15.2082C1.86544 14.2174 2.46699 13.3171 3.22534 12.5588C3.98368 11.8004 4.88397 11.1989 5.87479 10.7885C6.86562 10.3781 7.92758 10.1668 9.00004 10.1668C10.0725 10.1668 11.1345 10.3781 12.1253 10.7885C13.1161 11.1989 14.0164 11.8004 14.7747 12.5588C15.5331 13.3171 16.1346 14.2174 16.5451 15.2082C16.9555 16.1991 17.1667 17.261 17.1667 18.3335H0.833374Z"
                      fill="#555555"
                    ></path>
                  </svg>
                  <svg className="h-6 w-6 border-gray-400">
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="border-t"></div>
            <div>
              <aside className="flex my-8 mx-8 mt-6 overflow-x-scroll">
                <div className="relative flex h-50 w-110 flex-col justify-between overflow-hidden rounded-3xl border border-gray-200 bg-gray-100 px-6 py-8 shadow-lg mr-6">
                  <div>
                    <h2
                      className="mb-3"
                      style={{
                        lineHeight: '2.2rem',
                      }}
                    >
                      Đánh dấu học viên đã bỏ học
                    </h2>
                    <div className="p4 max-w-80 mb-3">
                      Gửi một loạt email để thu hút lại những sinh viên bỏ dở
                      quy trình thanh toán
                    </div>
                  </div>
                  <div className="prose-product-sm sm:prose-product-lg">
                    <button
                      data-cy=""
                      className="justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-12 px-6 b2 rounded-lg border border-gray-300 bg-white box hover:border-gray-500 hover:bg-gray-100 disabled:bg-gray-300"
                      type="button"
                    >
                      Check it out
                    </button>
                  </div>
                  <div className="absolute right-0 bottom-0 h-36 w-36">
                    <span
                      styles={{
                        boxSizing: 'border-box',
                        display: 'block',
                        overflow: 'hidden',
                        width: 'initial',
                        height: 'initial',
                        background: 'none',
                        opacity: 1,
                        border: '0px',
                        margin: '0px',
                        padding: '0px',
                        position: 'absolute',
                        inset: '0px',
                      }}
                    >
                      <img
                        src="https://maven.com/_next/image?url=https%3A%2F%2Fd3fe4umbwqdrlj.cloudfront.net%2Fadmin-dashboard%2Fphone-with-plane-2.png&w=1536&q=75"
                        decoding="async"
                        data-nimg="fill"
                        sizes="100vw"
                        styles={{
                          position: 'absolute',
                          inset: '0px',
                          boxSizing: 'border-box',
                          padding: '0px',
                          border: 'none',
                          margin: 'auto',
                          display: 'block',
                          width: '0px',
                          height: '0px',
                          minWidth: '100%',
                          maxWidth: '100%',
                          minHeight: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                          objectPosition: 'right bottom',
                        }}
                      />
                    </span>
                  </div>
                </div>
              </aside>
              <div className="mx-8 flex h-15 items-center justify-between border-b">
                <div className="flex h-full">
                  <div className="mr-6 flex cursor-pointer items-center border-b-4 border-primary-300 text-gray-900 transition ease-out duration-200">
                    <p className="l1 uppercase">Chiến dịch</p>
                  </div>
                  <div className="mr-6 flex cursor-pointer items-center border-b-4 border-hidden border-transparent">
                    <p className="l1 uppercase">Email hoạt động</p>
                  </div>
                  <div className="mr-6 flex cursor-pointer items-center border-b-4 border-hidden border-transparent">
                    <p className="l1 uppercase">Thông báo</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="b2 mr-2">Lọc qua</label>
                  <div className="b2 undefined css-b62m3t-container">
                    <span
                      id="react-select-2-live-region"
                      className="css-7pg0cj-a11yText"
                    ></span>
                    <span
                      aria-live="polite"
                      aria-atomic="false"
                      aria-relevant="additions text"
                      className="css-7pg0cj-a11yText"
                    ></span>
                    <div className="flex cursor-pointer items-center rounded-lg bg-gray-100 pl-2.5 css-0">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M16.5 2.25V5.25"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M7.5 2.25V5.25"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M3.75 8.25H20.25"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M8.625 11.9995H11.25L9.75 13.8745C9.99664 13.8745 10.2395 13.9353 10.457 14.0516C10.6745 14.1678 10.86 14.3359 10.9971 14.541C11.1341 14.746 11.2185 14.9817 11.2427 15.2271C11.267 15.4726 11.2303 15.7202 11.136 15.9481C11.0417 16.176 10.8926 16.3771 10.702 16.5337C10.5114 16.6902 10.2852 16.7973 10.0433 16.8456C9.80143 16.8938 9.55139 16.8816 9.31534 16.8102C9.07928 16.7387 8.86449 16.6101 8.68999 16.4358"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M13.5 13.1245L15 11.9995V16.8745"
                          stroke="#1E1E1F"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      <div
                        className="css-1d8n9bt"
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div
                          className="css-qc6sy-singleValue"
                          style={{
                            position: 'absolute',
                            top: '11px',
                            left: '33px',
                          }}
                        >
                          30 ngày gần nhất
                        </div>
                        <div className="css-ackcql" data-value="">
                          <input
                            className=""
                            autocapitalize="none"
                            autocomplete="off"
                            autocorrect="off"
                            id="react-select-2-input"
                            spellcheck="false"
                            tabindex="0"
                            type="text"
                            aria-autocomplete="list"
                            aria-expanded="false"
                            aria-haspopup="true"
                            role="combobox"
                            value=""
                            style={{
                              color: 'inherit',
                              background: '0px center',
                              opacity: 1,
                              width: '100%',
                              gridArea: '1 / 2 / auto / auto',
                              font: 'inherit',
                              minWidth: '2px',
                              border: '0px',
                              margin: '0px',
                              outline: '0px',
                              padding: '0px',
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          position: 'absolute',
                          right: '50px',
                        }}
                        className="css-1wy0on6"
                      >
                        <span className="css-0"></span>
                        <div
                          className="css-tlfecz-indicatorContainer"
                          aria-hidden="true"
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <input name="date-filter" type="hidden" value="30days" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="-mr-20 flex flex-col overflow-y-scroll pr-20"
              styles={{ height: 'calc(100vh - 80px)' }}
            >
              <div className="flex px-6">
                <div className="grow">
                  <div className="mb-18">
                    <div className="mt-10 overflow-hidden rounded-xl border border-gray-300">
                      <div className="group flex cursor-pointer items-center justify-between p-6 hover:bg-gray-100">
                        <div className="flex max-w-150">
                          <div className="mr-3 flex h-12 w-12 items-center rounded-full bg-gray-100">
                            <svg
                              className="mx-auto"
                              width="43"
                              height="43"
                              viewBox="0 0 43 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_1569_60462)">
                                <path
                                  d="M19.828 24.6672L7.05721 19.0416L35.2629 14.4129L19.828 24.6672Z"
                                  fill="#009E3D"
                                ></path>
                                <path
                                  d="M29.8315 34.9274L36.6528 16.0102L23.8675 28.6659L29.8315 34.9274Z"
                                  fill="#FFD954"
                                ></path>
                                <path
                                  d="M17.8008 33.3299L37.3524 14.0701L20.7741 25.0839L17.8008 33.3299Z"
                                  fill="#2ECCEF"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="clip0_1569_60462">
                                  <rect
                                    width="33"
                                    height="33"
                                    fill="white"
                                    transform="translate(31.4385 42.6323) rotate(-160.171)"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div>
                            <h3 className="mb-1">
                              Thiết lập danh sách chờ của bạn
                            </h3>
                            <div className="p3 mb-4 text-gray-700">
                              Giữ cho đối tượng trong danh sách chờ của bạn
                              tương tác và quan tâm
                            </div>
                            <div className="p3">
                              4 Active Emails · 0% Open Rate · 0% Click Rate
                            </div>
                          </div>
                        </div>
                        <div className="b1 flex text-gray-900 group-hover:text-primary">
                          Xem thêm
                          <svg
                            className="ml-2"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 8H13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M8 2.5V13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 overflow-hidden rounded-xl border border-gray-300">
                      <div className="group flex cursor-pointer items-center justify-between p-6 hover:bg-gray-100">
                        <div className="flex max-w-150">
                          <div className="mr-3 flex h-12 w-12 items-center rounded-full bg-gray-100">
                            <svg
                              className="mx-auto"
                              width="27"
                              height="27"
                              viewBox="0 0 27 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15 3V15H3C3 21.6274 8.37258 27 15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3Z"
                                fill="#009E3D"
                              ></path>
                              <path
                                d="M14 14V1C6.8203 1 1 6.8203 1 14H14Z"
                                fill="#FFD954"
                              ></path>
                            </svg>
                          </div>
                          <div>
                            <h3 className="mb-1">Ứng dụng chưa hoàn thành</h3>
                            <div className="p3 mb-4 text-gray-700">
                              Nhắc nhở qua email cho những sinh viên bắt đầu
                              đăng ký nhưng không hoàn thành
                            </div>
                            <div className="p3">
                              3 Active Emails · 0% Open Rate · 0% Click Rate
                            </div>
                          </div>
                        </div>
                        <div className="b1 flex text-gray-900 group-hover:text-primary">
                          Xem thêm
                          <svg
                            className="ml-2"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 8H13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M8 2.5V13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 overflow-hidden rounded-xl border border-gray-300">
                      <div className="group flex cursor-pointer items-center justify-between p-6 hover:bg-gray-100">
                        <div className="flex max-w-150">
                          <div className="mr-3 flex h-12 w-12 items-center rounded-full bg-gray-100">
                            <svg
                              className="mx-auto"
                              width="33"
                              height="33"
                              viewBox="0 0 33 33"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.5 28C8.11929 28 7 26.8807 7 25.5C7 24.1193 8.11929 23 9.5 23C10.8807 23 12 24.1193 12 25.5C12 26.8807 10.8807 28 9.5 28Z"
                                fill="#2ECCEF"
                              ></path>
                              <path
                                d="M22.5 28C21.1193 28 20 26.8807 20 25.5C20 24.1193 21.1193 23 22.5 23C23.8807 23 25 24.1193 25 25.5C25 26.8807 23.8807 28 22.5 28Z"
                                fill="#2ECCEF"
                              ></path>
                              <path
                                d="M5.50667 15.3511C5.26151 14.6974 5.74479 14 6.443 14H28.099C28.9041 14 29.3792 14.903 28.923 15.5665L24.798 21.5665C24.6115 21.8379 24.3033 22 23.974 22H8.693C8.27616 22 7.90304 21.7414 7.75667 21.3511L5.50667 15.3511Z"
                                fill="#009E3D"
                              ></path>
                              <path
                                d="M13.5066 7.07367C13.1217 6.407 13.6028 5.57367 14.3726 5.57367L20.0556 5.57366C20.8254 5.57366 21.3065 6.407 20.9216 7.07366L18.0801 11.9953C17.6952 12.662 16.733 12.662 16.3481 11.9953L13.5066 7.07367Z"
                                fill="#FFD954"
                              ></path>
                              <rect
                                x="16"
                                y="2"
                                width="2.5"
                                height="5"
                                rx="1.25"
                                fill="#FFD954"
                              ></rect>
                              <rect
                                x="3"
                                y="10.9978"
                                width="2"
                                height="8.18819"
                                rx="1"
                                transform="rotate(-29.9278 3 10.9978)"
                                fill="#009E3D"
                              ></rect>
                            </svg>
                          </div>
                          <div>
                            <h3 className="mb-1">Đánh dấu học viên bỏ học</h3>
                            <div className="p3 mb-4 text-gray-700">
                              Gửi một loạt email để thu hút lại những sinh viên
                              bỏ dở quy trình thanh toán
                            </div>
                            <div className="p3">
                              3 Active Emails · 0% Open Rate · 0% Click Rate
                            </div>
                          </div>
                        </div>
                        <div className="b1 flex text-gray-900 group-hover:text-primary">
                          Xem thêm
                          <svg
                            className="ml-2"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 8H13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M8 2.5V13.5"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="p3 mt-6 ml-11">
                      Tất cả số liệu thống kê đều dựa trên các hoạt động kể từ
                      ngày 12 tháng 12 năm 2022.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Email
