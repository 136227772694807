import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
const SetUp = () => {
  return (
    <div className="-ml-4 space-y-2 grow overflow-hidden rounded-tl-3xl rounded-bl-3xl border-l bg-white shadow-md">
      <div className="undefined">
        <div className="prose-product-sm flex h-full flex-col sm:prose-product-lg">
          <div className="z-10 flex h-20 items-center justify-between py-2 px-8">
            <div className="grow">
              <div className="mr-6 flex flex-auto justify-between">
                <div className="flex items-center">
                  <div className="mr-6 font-graphik text-xl font-bold">
                    Bắt đầu cài đặt
                  </div>
                  <div className="mr-2"></div>
                </div>
                <div className="flex items-center"></div>
              </div>
            </div>
            <div className="relative inline-block text-left">
              <button
                className="flex items-center justify-items-center rounded-full border border-gray-200 py-2 px-3 transition duration-200 ease-out hover:border-gray-300 hover:bg-gray-100 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <svg className="mt-1 h-6 w-6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.00004 7.8335C9.9283 7.8335 10.8185 7.46475 11.4749 6.80837C12.1313 6.15199 12.5 5.26175 12.5 4.3335C12.5 3.40524 12.1313 2.515 11.4749 1.85862C10.8185 1.20225 9.9283 0.833496 9.00004 0.833496C8.07178 0.833496 7.18154 1.20225 6.52517 1.85862C5.86879 2.515 5.50004 3.40524 5.50004 4.3335C5.50004 5.26175 5.86879 6.15199 6.52517 6.80837C7.18154 7.46475 8.07178 7.8335 9.00004 7.8335ZM0.833374 18.3335C0.833374 17.261 1.04461 16.1991 1.45502 15.2082C1.86544 14.2174 2.46699 13.3171 3.22534 12.5588C3.98368 11.8004 4.88397 11.1989 5.87479 10.7885C6.86562 10.3781 7.92758 10.1668 9.00004 10.1668C10.0725 10.1668 11.1345 10.3781 12.1253 10.7885C13.1161 11.1989 14.0164 11.8004 14.7747 12.5588C15.5331 13.3171 16.1346 14.2174 16.5451 15.2082C16.9555 16.1991 17.1667 17.261 17.1667 18.3335H0.833374Z"
                    fill="#555555"
                  ></path>
                </svg>
                <svg className="h-6 w-6 border-gray-400">
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="border-t"></div>
          <div
            className="-mr-20 flex flex-col overflow-y-scroll pr-20 "
            style={{ height: 'calc(100vh - 80px)' }}
          >
            <div className="flex px-6">
              <div className="grow">
                <div className="mx-auto ">
                  <div className="my-8">
                    <div className="prose-product-lg ">
                      <div className="m-8">
                        <Accordion>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <div className="flex cursor-pointer justify-between flex-1">
                              <div className="flex flex-1">
                                <div className="ml-4 space-y-2 mt-5">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.54.875a1.253 1.253 0 0 1 1.574.006L11.54.875Zm0 0 1.574.006c1.03.836 3.262 2.92 4.494 6.122.429 1.116.735 2.363.828 3.734l3.329 3.995a1.254 1.254 0 0 1 .26 1.075l-1.32 5.942a1.254 1.254 0 0 1-2.008.707l-3.092-2.474H9.05l-3.093 2.474a1.254 1.254 0 0 1-2.008-.707l-1.32-5.942a1.254 1.254 0 0 1 .26-1.075l3.264-3.916c.084-1.405.396-2.68.838-3.82C8.236 3.792 10.5 1.709 11.54.876Zm4.719 18.606c1.506-2.757 2.134-5.254 2.2-7.466l2.691 3.229a.456.456 0 0 1 .094.39l-1.32 5.941a.454.454 0 0 1-.727.256l-2.938-2.35Zm-7.865.002c-1.522-2.73-2.169-5.205-2.253-7.401l-2.636 3.162-.307-.256.307.256a.454.454 0 0 0-.094.39l1.32 5.941a.454.454 0 0 0 .728.256l2.935-2.348Zm-1.449-8.48c-.113 2.337.445 5.071 2.197 8.18h6.369c2.726-4.94 2.487-8.935 1.35-11.892-1.16-3.019-3.274-4.996-4.251-5.79a.452.452 0 0 0-.57-.001l-.25-.313.25.313c-.987.791-3.13 2.767-4.302 5.785a11.79 11.79 0 0 0-.793 3.718Zm3.674 9.497c-.221 0-.4.09-.4.2 0 .11.179.2.4.2h3.417c.221 0 .4-.09.4-.2 0-.11-.179-.2-.4-.2H10.62Zm-.4.9c0-.11.179-.2.4-.2h3.417c.221 0 .4.09.4.2 0 .11-.179.2-.4.2H10.62c-.221 0-.4-.09-.4-.2Zm.4.5c-.221 0-.4.09-.4.2 0 .11.179.2.4.2h3.417c.221 0 .4-.09.4-.2 0-.11-.179-.2-.4-.2H10.62Zm0 .7c-.221 0-.4.09-.4.2 0 .11.179.2.4.2h3.417c.221 0 .4-.09.4-.2 0-.11-.179-.2-.4-.2H10.62Zm2.777-13.27a1.068 1.068 0 1 1-2.136 0 1.068 1.068 0 0 1 2.136 0Z"
                                      fill="currentColor"
                                      clip-rule="evenodd"
                                      fill-rule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                                <div className="m-4 space-y-3">
                                  <h2>Thiết lập khóa học</h2>
                                  <p className="text-xs text-gray-700">
                                    Hãy hoàn thành các bước cài đặt khóa học của
                                    bạn.
                                  </p>
                                </div>
                              </div>
                              <div className="flex max-w-64 flex-1 items-center">
                                <div className="flex flex-1 items-center">
                                  <figcaption className="w-11 shrink-0 pr-2 text-right text-sm text-gray-600">
                                    22%
                                  </figcaption>
                                  <div className="border-box h-3 w-full rounded-full border border-gray-300 bg-gray-200 shadow-inner">
                                    <div
                                      className="h-full rounded-full"
                                      style={{
                                        width: '22%',
                                        background:
                                          'linear-gradient(148deg,rgb(0, 118, 94) 0%,rgb(0, 88, 71) 72.57%)',
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <svg
                                  className="m-4 flex items-center"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 6L8 11L3 6"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>
                              <li>
                                <div className="prose-product-sm sm:prose-product-lg border border-gray-200 p-4 hover:bg-gray-100 bg-gray-100 rounded-t-md">
                                  <div className="flex grow items-center justify-between">
                                    <div className="flex">
                                      <svg
                                        className="m-auto"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z"
                                          fill="#009E3D"
                                        ></path>
                                      </svg>
                                      <div className="ml-4 space-y-2">
                                        <h4 className="text-gray-600">
                                          Tạo tài khoản
                                        </h4>
                                      </div>
                                    </div>
                                    <svg
                                      className="mr-4 text-gray-600 -rotate-90"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13 6L8 11L3 6"
                                        stroke="currentColor"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="prose-product-sm sm:prose-product-lg border border-gray-200 p-4 hover:bg-gray-100 bg-gray-100">
                                  <div className="flex grow items-center justify-between">
                                    <div className="flex">
                                      <svg
                                        className="m-auto"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z"
                                          fill="#009E3D"
                                        ></path>
                                      </svg>
                                      <div className="ml-4 space-y-2">
                                        <h4 className="text-gray-600">
                                          Tạo lớp học
                                        </h4>
                                      </div>
                                    </div>
                                    <svg
                                      className="mr-4 text-gray-600 -rotate-90"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M13 6L8 11L3 6"
                                        stroke="currentColor"
                                        stroke-width="1.2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="rounded-xl border border-solid border-gray-900 shadow-lg">
                                  <div className="prose-product-sm sm:prose-product-lg border-b rounded-t-xl border-gray-200  hover:bg-gray-100 cursor-pointer">
                                    <Accordion
                                      sx={{
                                        p: 1,
                                      }}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div className="flex grow items-center justify-between">
                                          <div className="flex">
                                            <svg
                                              className="m-auto"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                                                fill="#0D0D0C"
                                              ></path>
                                            </svg>
                                            <div className="ml-4 space-y-2">
                                              <h4 className="">
                                                Chỉnh sửa tên và URL khóa học
                                                của bạn
                                              </h4>
                                              <p className="p3 text-gray-700">
                                                Chúng tôi đã bắt đầu khóa học
                                                của bạn với một tên giữ chỗ và
                                                URL. Cập nhật những thứ này
                                                trước khi khởi chạy.
                                              </p>
                                            </div>
                                          </div>
                                          <svg
                                            className="mr-4 text-gray-600"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13 6L8 11L3 6"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="p-8 pt-5">
                                          <div>
                                            <div className="mb-6">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label className="mb-3">
                                                    Tên lớp
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                                                <input
                                                  className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200 undefined"
                                                  type="text"
                                                  maxlength="40"
                                                  value="Mikah Dang"
                                                />
                                              </div>
                                              <div className="mt-1 flex justify-between">
                                                <div className="prose-product-sm sm:prose-product-lg">
                                                  <p className="p4 text-gray-600">
                                                    Đây là ngôi nhà cho (các)
                                                    khóa học của bạn. Nếu bạn
                                                    không chắc chắn, chúng tôi
                                                    khuyên bạn nên sử dụng tên
                                                    của mình.
                                                  </p>
                                                </div>
                                                <div className="ml-3 justify-self-end">
                                                  <p className="text-right text-xs text-gray-700">
                                                    17/40
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mb-6">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label className="mb-3">
                                                    Tên khóa học
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                                                <input
                                                  className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200 undefined"
                                                  type="text"
                                                  maxlength="80"
                                                  value=""
                                                />
                                              </div>
                                              <div className="mt-1 flex justify-between">
                                                <div className="prose-product-sm sm:prose-product-lg">
                                                  <p className="p4 text-gray-600">
                                                    Điều này hiển thị trên trang
                                                    đích của bạn và trong cổng
                                                    thông tin sinh viên.
                                                  </p>
                                                </div>
                                                <div className="ml-3 justify-self-end">
                                                  <p className="text-right text-xs text-gray-700">
                                                    23/80
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div>
                                                <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                                                  <div className="prose-product-lg">
                                                    <h5 className="flex justify-between text-gray-800">
                                                      <label className="mb-3">
                                                        URL Khóa học
                                                      </label>
                                                    </h5>
                                                  </div>
                                                  <div className="b2 text-primary cursor-pointer">
                                                    Edit
                                                  </div>
                                                </div>
                                                <div className="mb-3 prose-product-sm flex items-center sm:prose-product-lg">
                                                  <div className="rounded-l-lg border border-r-0 border-gray-300 bg-gray-100 py-3.5 px-3 text-gray-600 shadow-sm flex-shrink">
                                                    maven.com/
                                                  </div>
                                                  <input
                                                    className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-3 w-full border rounded-r-lg border-gray-300 bg-gray-200 text-gray-600 flex-grpw"
                                                    style={{
                                                      padding: '26px 15px',
                                                      marginBottom: '0px',
                                                    }}
                                                    type="text"
                                                    disabled=""
                                                    maxlength="121"
                                                    value="Mikah-Dang/placeholder-yoympl"
                                                  />
                                                </div>
                                                <div className="mt-1 flex justify-between">
                                                  <div className="prose-product-sm sm:prose-product-lg">
                                                    <p className="p4 text-gray-600">
                                                      Hãy cẩn thận! Việc cập
                                                      nhật URL khóa học của bạn
                                                      sẽ phá vỡ mọi liên kết
                                                      hiện có mà bạn đã chia sẻ.
                                                    </p>
                                                  </div>
                                                  <div className="ml-3 justify-self-end">
                                                    <p className="text-right text-xs text-gray-700">
                                                      36/121
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="rounded-xl border border-solid border-gray-900 shadow-lg">
                                  <div className="prose-product-sm sm:prose-product-lg border-b rounded-t-xl border-gray-200  hover:bg-gray-100 cursor-pointer">
                                    <Accordion
                                      sx={{
                                        p: 1,
                                      }}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div className="flex grow items-center justify-between">
                                          <div className="flex">
                                            <svg
                                              className="m-auto"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                                                fill="#0D0D0C"
                                              ></path>
                                            </svg>
                                            <div className="ml-4 space-y-2">
                                              <h4 className="">
                                                Hoàn thiện thông tin giảng viên
                                              </h4>
                                              <p className="p3 text-gray-700">
                                                Thêm ảnh để cá nhân hóa trang
                                                đích của bạn và khảo sát sở
                                                thích khóa học
                                              </p>
                                            </div>
                                          </div>
                                          <svg
                                            className="mr-4 text-gray-600"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13 6L8 11L3 6"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="p-8 pt-5">
                                          <div>
                                            <div className="flex justify-between space-x-8">
                                              <div className="w-full space-y-4">
                                                <div className="space-y-1">
                                                  <div className="w-full rounded-lg p-3 cursor-pointer border bg-white text-gray-800 border-primary-dark">
                                                    <h5>Mikah Dang</h5>
                                                  </div>
                                                </div>
                                                <div className="prose-product-sm sm:prose-product-lg">
                                                  <button className="b2 flex items-center text-primary hover:text-primary-200 disabled:text-gray-500">
                                                    <svg
                                                      className="mr-2"
                                                      width="16"
                                                      height="16"
                                                      viewBox="0 0 16 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M2.5 8H13.5"
                                                        stroke="currentColor"
                                                        stroke-width="1.2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M8 2.5V13.5"
                                                        stroke="currentColor"
                                                        stroke-width="1.2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      ></path>
                                                    </svg>
                                                    Thêm giảng viên
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="w-full space-y-4">
                                                <div className="space-y-4">
                                                  <div>
                                                    <h5 className="my-2 text-gray-700">
                                                      Tên giảng viên
                                                    </h5>
                                                    <div className="h-full w-full undefined">
                                                      <div className="border border-gray-300 rounded-xl bg-white shadow-sm">
                                                        <div
                                                          className="ql-container ql-snow position-relative"
                                                          style={{
                                                            height: '55px',
                                                          }}
                                                        >
                                                          <div
                                                            className="ql-editor"
                                                            style={{
                                                              position:
                                                                'absolute',
                                                              left: '22px',
                                                              top: '10px',
                                                            }}
                                                            data-gramm="false"
                                                            contenteditable="true"
                                                            data-placeholder="Add instructor name..."
                                                            tabindex="1"
                                                          >
                                                            <p>Mikah Dang</p>
                                                          </div>
                                                          <div
                                                            className="ql-clipboard"
                                                            contenteditable="true"
                                                            tabindex="-1"
                                                          ></div>
                                                        </div>
                                                      </div>
                                                      <div className="flex flex-row-reverse justify-between">
                                                        <p className="mt-1 text-right text-xs text-gray-700">
                                                          17/140
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="prose-product-lg flex w-full flex-col items-center">
                                                    <div className="group relative block w-full cursor-pointer">
                                                      <div className="absolute flex h-full w-full flex-col items-center justify-center opacity-0 transition-opacity transition duration-200 group-hover:bg-gray-100 group-hover:opacity-95">
                                                        <svg
                                                          className="text-gray-700"
                                                          width="48"
                                                          height="48"
                                                          viewBox="0 0 48 48"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M33.2429 18.8575H41.9702V10.1302"
                                                            stroke="currentColor"
                                                            stroke-width="3"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                          ></path>
                                                          <path
                                                            d="M13.1716 12.6863C14.6574 11.2006 16.4212 10.022 18.3624 9.21793C20.3036 8.41385 22.3842 8 24.4853 8C26.5865 8 28.6671 8.41385 30.6083 9.21793C32.5495 10.022 34.3133 11.2006 35.7991 12.6863L41.9702 18.8574"
                                                            stroke="currentColor"
                                                            stroke-width="3"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                          ></path>
                                                          <path
                                                            d="M15.7273 29.1425H7V37.8698"
                                                            stroke="currentColor"
                                                            stroke-width="3"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                          ></path>
                                                          <path
                                                            d="M35.799 35.3137C34.3133 36.7994 32.5495 37.978 30.6083 38.7821C28.6671 39.5862 26.5865 40 24.4853 40C22.3842 40 20.3036 39.5862 18.3624 38.7821C16.4212 37.978 14.6573 36.7994 13.1716 35.3137L7.00049 29.1426"
                                                            stroke="currentColor"
                                                            stroke-width="3"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                          ></path>
                                                        </svg>
                                                        <h4 className="pt-2 text-gray-700">
                                                          Thay ảnh
                                                        </h4>
                                                      </div>
                                                      <div className="flex h-50 w-full flex-col items-center rounded-md border border-solid border-gray-400 bg-white p-3">
                                                        <img
                                                          className="h-full w-full object-contain"
                                                          src="https://d3fe4umbwqdrlj.cloudfront.net/admin-dashboard/avatar-placeholder.jpg"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="prose-product-sm sm:prose-product-lg">
                                                      <button
                                                        data-cy=""
                                                        className="mt-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg border border-gray-300 bg-white box hover:border-gray-500 hover:bg-gray-100 disabled:bg-gray-300"
                                                        type="button"
                                                      >
                                                        Thay ảnh
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="rounded-xl border border-solid border-gray-900 shadow-lg">
                                  <div className="prose-product-sm sm:prose-product-lg border-b rounded-t-xl border-gray-200  hover:bg-gray-100 cursor-pointer">
                                    <Accordion
                                      sx={{
                                        p: 1,
                                      }}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div className="flex grow items-center justify-between">
                                          <div className="flex">
                                            <svg
                                              className="m-auto"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                                                fill="#0D0D0C"
                                              ></path>
                                            </svg>
                                            <div className="ml-4 space-y-2">
                                              <h4 className="">
                                                Đặt ngày lớp học
                                              </h4>
                                              <p className="p3 text-gray-700">
                                                Chọn ngày quan trọng cho nhóm
                                                của bạn
                                              </p>
                                            </div>
                                          </div>
                                          <svg
                                            className="mr-4 text-gray-600"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13 6L8 11L3 6"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="p-8 pt-5">
                                          <form action="#">
                                            <div className="mt-6 first:mt-0">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label
                                                    className="mb-3"
                                                    for="start_date"
                                                  >
                                                    Ngày bắt đầu khóa học
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                  <input
                                                    type="text"
                                                    autocomplete="off"
                                                    className="w-full rounded-md border border-gray-300 bg-white py-3.5 px-4 shadow-sm transition duration-200 ease-out hover:border-gray-400 hover:shadow-md"
                                                    value=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="prose-product-sm mt-1 sm:prose-product-lg">
                                                <p className="p4 text-gray-600">
                                                  Ngày đầu tiên trong nhóm của
                                                  bạn, thường là hội thảo trực
                                                  tiếp đầu tiên. Điều này hiển
                                                  thị trên trang đích khóa học
                                                  của bạn.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mt-6 first:mt-0">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label
                                                    className="mb-3"
                                                    for="end_date"
                                                  >
                                                    Ngày kết thúc nhóm
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                  <input
                                                    type="text"
                                                    autocomplete="off"
                                                    className="w-full rounded-md border border-gray-300 bg-white py-3.5 px-4 shadow-sm transition duration-200 ease-out hover:border-gray-400 hover:shadow-md"
                                                    value=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="prose-product-sm mt-1 sm:prose-product-lg">
                                                <p className="p4 text-gray-600">
                                                  Thời hạn học viên đóng tiền và
                                                  nhập học. Thời gian ở múi giờ
                                                  địa phương của bạn.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mt-6 first:mt-0">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label
                                                    className="mb-3"
                                                    for="payment_deadline"
                                                  >
                                                    Hạn chót thanh toán
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                  <input
                                                    type="text"
                                                    autocomplete="off"
                                                    className="w-full rounded-md border border-gray-300 bg-white py-3.5 px-4 shadow-sm transition duration-200 ease-out hover:border-gray-400 hover:shadow-md"
                                                    value=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="prose-product-sm mt-1 sm:prose-product-lg">
                                                <p className="p4 text-gray-600">
                                                  Thời hạn sinh viên đóng tiền
                                                  và nhập học. Thời gian ở múi
                                                  giờ địa phương của bạn.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mt-6 first:mt-0">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label
                                                    className="mb-3"
                                                    for="portal_open_date"
                                                  >
                                                    Ngày mở cổng thông tin học
                                                    viên
                                                  </label>
                                                </h5>
                                              </div>
                                              <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                  <input
                                                    type="text"
                                                    autocomplete="off"
                                                    className="w-full rounded-md border border-gray-300 bg-white py-3.5 px-4 shadow-sm transition duration-200 ease-out hover:border-gray-400 hover:shadow-md"
                                                    value=""
                                                  />
                                                </div>
                                              </div>
                                              <div className="prose-product-sm mt-1 sm:prose-product-lg">
                                                <p className="p4 mb-2 text-gray-600">
                                                  Thời gian (theo múi giờ địa
                                                  phương của bạn) sinh viên có
                                                  thể truy cập cổng thông tin
                                                  sinh viên của họ để xem giáo
                                                  trình và sự kiện của bạn.
                                                  Chúng tôi khuyên bạn nên gửi
                                                  email định hướng cho các sinh
                                                  viên đã đăng ký sau khi cổng
                                                  thông tin của bạn được mở.
                                                  <a
                                                    className="underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://help.maven.com/en/articles/5597250-student-orientation-checklist"
                                                  >
                                                    orientation email
                                                  </a>
                                                  to enrolled students once your
                                                  portal is open.
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mx-4 mt-6 grow sm:mx-0">
                                              <div className="prose-product-lg">
                                                <h5 className="flex justify-between text-gray-800">
                                                  <label
                                                    className="mb-3"
                                                    for="Maximum Number of Students"
                                                  >
                                                    Số lượng học viên tối đa
                                                  </label>
                                                  <span className="p4">
                                                    (Optional)
                                                  </span>
                                                </h5>
                                              </div>
                                              <div className="prose-product-sm flex items-center sm:prose-product-lg">
                                                <input
                                                  className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200 undefined"
                                                  placeholder=""
                                                  type="number"
                                                  schema="[object Object]"
                                                  name="maximum_size"
                                                  min="0"
                                                  value=""
                                                />
                                              </div>
                                              <div className="mt-1 flex justify-between">
                                                <div className="prose-product-sm sm:prose-product-lg">
                                                  <p className="p4 mb-2 text-gray-600">
                                                    Đây là số lượng sinh viên
                                                    tối đa có thể tham gia nhóm
                                                    của bạn. Sau khi đạt đến con
                                                    số này, khách truy cập trang
                                                    đích sẽ thấy trường danh
                                                    sách chờ.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="rounded-xl border border-solid border-gray-900 shadow-lg">
                                  <div className="prose-product-sm sm:prose-product-lg border-b rounded-t-xl border-gray-200  hover:bg-gray-100 cursor-pointer">
                                    <Accordion
                                      sx={{
                                        p: 1,
                                      }}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div className="flex grow items-center justify-between">
                                          <div className="flex">
                                            <svg
                                              className="m-auto"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                                                fill="#0D0D0C"
                                              ></path>
                                            </svg>
                                            <div className="ml-4 space-y-2">
                                              <h4 className="">
                                                Kết nối đến Momo
                                              </h4>
                                              <p className="p3 text-gray-700">
                                                Đây là cách bạn sẽ được thanh
                                                toán
                                              </p>
                                            </div>
                                          </div>
                                          <svg
                                            className="mr-4 text-gray-600"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13 6L8 11L3 6"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="p-8 pt-2">
                                          <div>
                                            <p className="p3 mb-6 text-gray-900">
                                              Chúng tôi đã hợp tác với Momo để
                                              cung cấp các khoản thanh toán linh
                                              hoạt cho người hướng dẫn
                                            </p>
                                            <div className="flex items-center">
                                              <div className="prose-product-sm sm:prose-product-lg">
                                                <button
                                                  data-cy=""
                                                  className="ml-2 justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-gray-900 text-white hover:bg-black disabled:bg-gray-500"
                                                  type="button"
                                                >
                                                  Kết nối với Momo
                                                </button>
                                              </div>
                                            </div>
                                            <p className="p4 mt-2 text-gray-700">
                                              Nếu quốc gia của bạn không có
                                              trong danh sách thả xuống, hãy
                                              liên hệ với
                                              <span className="text-primary-300">
                                                {' '}
                                                support@maven.com
                                              </span>
                                              .
                                            </p>
                                          </div>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="rounded-xl border border-solid border-gray-900 shadow-lg">
                                  <div className="prose-product-sm sm:prose-product-lg border-b rounded-t-xl border-gray-200  hover:bg-gray-100 cursor-pointer">
                                    <Accordion
                                      sx={{
                                        p: 1,
                                      }}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <div className="flex grow items-center justify-between">
                                          <div className="flex">
                                            <svg
                                              className="m-auto"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
                                                fill="#0D0D0C"
                                              ></path>
                                            </svg>
                                            <div className="ml-4 space-y-2">
                                              <h4 className="">
                                                Thiết lập giá khóa học của bạn
                                              </h4>
                                              <p className="p3 text-gray-700">
                                                Chọn một điểm giá cho khóa học
                                                của bạn
                                              </p>
                                            </div>
                                          </div>
                                          <svg
                                            className="mr-4 text-gray-600"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13 6L8 11L3 6"
                                              stroke="currentColor"
                                              stroke-width="1.2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </div>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div className="p-8 pt-5">
                                          <div>
                                            <div className="text-accent4-400 flex items-center">
                                              <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M8 6.5V9"
                                                  stroke="currentColor"
                                                  stroke-width="1.2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>
                                                <path
                                                  d="M7.13451 2.49904L1.63599 11.9986C1.54801 12.1506 1.50161 12.3231 1.50147 12.4987C1.50132 12.6743 1.54743 12.8469 1.63516 12.999C1.7229 13.1512 1.84915 13.2776 2.00123 13.3654C2.1533 13.4533 2.32584 13.4995 2.50147 13.4995H13.4985C13.6741 13.4995 13.8467 13.4533 13.9987 13.3654C14.1508 13.2776 14.2771 13.1512 14.3648 12.999C14.4525 12.8469 14.4986 12.6743 14.4985 12.4987C14.4984 12.3231 14.452 12.1506 14.364 11.9986L8.86545 2.49904C8.77761 2.34728 8.65141 2.22129 8.4995 2.1337C8.3476 2.04611 8.17533 2 7.99998 2C7.82463 2 7.65237 2.04611 7.50046 2.1337C7.34855 2.22129 7.22235 2.34728 7.13451 2.49904V2.49904Z"
                                                  stroke="currentColor"
                                                  stroke-width="1.2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>
                                                <path
                                                  d="M8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12Z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                              <h5 className="ml-2">
                                                Chưa kết nối đến Momo
                                              </h5>
                                            </div>
                                          </div>
                                          <p className="p3 mt-2 text-gray-700">
                                            Sau khi Momo được kết nối, bạn sẽ có
                                            thể đặt giá cho khóa học của mình
                                            tại đây. Bạn có thể kết nối Momo ở
                                            trên.
                                          </p>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                        <div className="mt-10">
                          <Accordion>
                            <AccordionSummary
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <div className="flex cursor-pointer justify-between flex-1">
                                <div className="flex flex-1">
                                  <div className="ml-4 mt-5">
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        stroke="currentColor"
                                        d="M1 7.9 12 2l11 5.9-11 5.8L1 8Z"
                                      ></path>
                                      <path
                                        stroke="currentColor"
                                        d="M17.5 21V10.9l-5.5-3"
                                      ></path>
                                      <path
                                        stroke="currentColor"
                                        d="M20.4 9.2v5l-.1.5c-.6.8-3.2 3.8-8.3 3.8-5 0-7.7-3-8.3-3.8l-.1-.5v-5"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="m-4 space-y-3">
                                    <h2>Chuẩn bị cho cohort của bạn</h2>
                                    <p className="text-xs text-gray-700">
                                      Danh sách kiểm tra này giúp bạn chuẩn bị
                                      cho cohort đầu tiên của bạn
                                    </p>
                                  </div>
                                </div>
                                <div className="flex max-w-64 flex-1 items-center">
                                  <figure className="flex flex-1 items-center">
                                    <figcaption className="w-11 shrink-0 pr-2 text-right text-sm text-gray-600">
                                      33%
                                    </figcaption>
                                    <div className="border-box h-3 w-full rounded-full border border-gray-300 bg-gray-200 shadow-inner">
                                      <div
                                        className="h-full rounded-full"
                                        style={{
                                          width: '33%',
                                          background:
                                            'linear-gradient(148deg, rgb(0, 118, 94) 0%, rgb(0, 88, 71) 72.57%)',
                                        }}
                                      ></div>
                                    </div>
                                  </figure>
                                  <svg
                                    className="m-4 flex items-center "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13 6L8 11L3 6"
                                      stroke="currentColor"
                                      stroke-width="1.2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul>
                                <li>
                                  <a href="#">
                                    <div className="prose-product-sm sm:prose-product-lg border border-gray-200 p-4 hover:bg-gray-100 bg-gray-100  rounded-t-md">
                                      <div className="flex grow items-center justify-between">
                                        <div className="flex">
                                          <svg
                                            className="false"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.7827 8.48668C13.9825 8.29601 13.9898 7.97951 13.7992 7.77976C13.6085 7.58001 13.292 7.57265 13.0923 7.76332L8.85414 11.8088L6.90774 9.95083C6.70799 9.76015 6.3915 9.76751 6.20083 9.96726C6.01015 10.167 6.01751 10.4835 6.21726 10.6742L8.5089 12.8617C8.70211 13.0461 9.00617 13.0461 9.19938 12.8617L13.7827 8.48668Z"
                                              fill="#009E3D"
                                            ></path>
                                          </svg>
                                          <div className="ml-4 space-y-2">
                                            <h4 className="text-gray-600">
                                              Thiết lập cộng đồng
                                            </h4>
                                            <p className="p3 text-gray-700">
                                              Tham quan nền tảng mới của chúng
                                              tôi và tùy chỉnh cộng đồng khóa
                                              học của bạn
                                            </p>
                                            <div className="pt-3">
                                              <div className="prose-product-sm sm:prose-product-lg">
                                                <button
                                                  data-cy=""
                                                  className=" justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg border border-gray-300 bg-white box hover:border-gray-500 hover:bg-gray-100 disabled:bg-gray-300"
                                                  type="button"
                                                >
                                                  Xem cộng đồng
                                                  <svg
                                                    className="ml-2"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M2.5 8H13.5"
                                                      stroke="currentColor"
                                                      stroke-width="1.2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    ></path>
                                                    <path
                                                      d="M9 3.5L13.5 8L9 12.5"
                                                      stroke="currentColor"
                                                      stroke-width="1.2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    ></path>
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <svg
                                          className="mr-4 text-gray-600 -rotate-90"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M13 6L8 11L3 6"
                                            stroke="currentColor"
                                            stroke-width="1.2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetUp
