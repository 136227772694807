import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/header'
const Img1 =
  'https://img.freepik.com/premium-vector/cartoon-illustration-thai-female-teacher-holding-stick-front-blackboard_49924-213.jpg?w=740'
const Img2 =
  'https://img.freepik.com/free-vector/excellent-student-with-good-grade-test-asian-student-uniform_40876-2763.jpg?w=900&t=st=1672238729~exp=1672239329~hmac=b6e83d7c50540a5384fa9cda4008e2c68dee95d8c91494822e2a5d7b2d168dc7'

const Navigation_Stu_Or_Teacher = () => {
  const [activeModal, setActiveModal] = React.useState(false)
  const titleRef = React.useRef()
  const courseRef = React.useRef()
  const contactRef = React.useRef()
  return (
    <div className="section full-height bg-white" style={{ padding: 0 }}>
      <div>
        <Header
          contactRef={contactRef}
          courseRef={courseRef}
          titleRef={titleRef}
          context={[activeModal, setActiveModal]}
        />
        <div className="prose-product-sm flex justify-center sm:prose-product-lg bg-white prose-website-sm w-full sm:prose-website-lg">
          <div className="px-4 sm:px-6 md:px-18 lg:px-28 xl:px-50 py-8 sm:py-16">
            <div className="w-full">
              <h1 className="mt-2 text-left text-black">
                Dưới đây là khóa học của bạn.
              </h1>
              <div className="mt-6">
                <h3 className="text-primary-dark">Với vai trò giảng viên</h3>
                <div className="mt-2">
                  <div className="mt-4 mb-4 lg:w-courserow">
                    <Link to="/admin/set-up">
                      <div className="group flex cursor-pointer justify-start overflow-hidden rounded-2xl border border-white/20 bg-white/10 shadow-md transition duration-300 ease-out hover:border-primary/100 hover:shadow-xl sm:shadow-lg">
                        <div className="relative w-48 shrink-0 rounded-l-4xl">
                          <div className="aspect-w-16 aspect-h-9">
                            <span
                              style={{
                                boxSizing: 'border-box',
                                display: 'block',
                                overflow: 'hidden',
                                width: 'initial',
                                height: 'initial',
                                background: 'none',
                                opacity: 1,
                                border: '0px',
                                margin: '0px',
                                padding: '0px',
                                position: 'absolute',
                                inset: '0px',
                              }}
                            >
                              <img
                                src={Img1}
                                decoding="async"
                                data-nimg="fill"
                                className="undefined duration-300 ease-in-out"
                                sizes="100vw"
                                style={{
                                  position: 'absolute',
                                  inset: '0px',
                                  boxSizing: 'border-box',
                                  padding: '0px',
                                  border: 'none',
                                  margin: 'auto',
                                  display: 'block',
                                  width: '0px',
                                  height: '0px',
                                  minWidth: '100%',
                                  maxWidth: '100%',
                                  minHeight: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="prose-website-sm flex cursor-pointer flex-col justify-evenly bg-white px-4 sm:prose-website-lg">
                          <h3>Khóa học của bạn</h3>
                          <div className="flex items-center text-gray-700 group-hover:text-gray-900">
                            <h5>Dashboard</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <h3 className="text-primary-dark">Với vai trò học viên</h3>
                <div className="mt-2">
                  <div className="mt-4 mb-4 lg:w-courserow">
                    <Link to="/mycourse">
                      <div className="group flex cursor-pointer justify-start overflow-hidden rounded-2xl border border-white/20 bg-white/10 shadow-md transition duration-300 ease-out hover:border-primary/100 hover:shadow-xl sm:shadow-lg">
                        <div className="relative w-48 shrink-0 rounded-l-4xl">
                          <div className="aspect-w-16 aspect-h-9">
                            <span
                              style={{
                                boxSizing: 'border-box',
                                display: 'block',
                                overflow: 'hidden',
                                width: 'initial',
                                height: 'initial',
                                background: 'none',
                                opacity: 1,
                                border: '0px',
                                margin: '0px',
                                padding: '0px',
                                position: 'absolute',
                                inset: '0px',
                              }}
                            >
                              <img
                                src={Img2}
                                decoding="async"
                                data-nimg="fill"
                                className="undefined duration-300 ease-in-out"
                                sizes="100vw"
                                style={{
                                  position: 'absolute',
                                  inset: '0px',
                                  boxSizing: 'border-box',
                                  padding: '0px',
                                  border: 'none',
                                  margin: 'auto',
                                  display: 'block',
                                  width: '0px',
                                  height: '0px',
                                  minWidth: '100%',
                                  maxWidth: '100%',
                                  minHeight: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="prose-website-sm flex cursor-pointer flex-col justify-evenly bg-white px-4 sm:prose-website-lg">
                          <h3>Khóa học của bạn</h3>
                          <div className="flex items-center text-gray-700 group-hover:text-gray-900">
                            <h5>Dashboard</h5>
                            <svg
                              className="ml-1 transition duration-300 ease-out group-hover:translate-x-1"
                              width="13"
                              height="13"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.67155 1.33333L14.6667 8L7.67155 14.6667L6.93842 13.9206L12.606 8.5191H1.33333V7.48089H12.606L6.93842 2.07939L7.67155 1.33333Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navigation_Stu_Or_Teacher
