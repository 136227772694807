import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Bar, Pie } from 'react-chartjs-2'
import { DataGrid } from '@mui/x-data-grid'

import { Stack, Box } from '@mui/system'
import { Typography } from '@mui/material'
ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
)
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Thông kê thu nhập trong năm',
    },
  },
}

const labels = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
]
const data = {
  labels,
  datasets: [
    {
      label: 'Đăng ký thành công',
      data: [1, 12, 25, 20, 2, 8, 9],
      backgroundColor: '#439A97',
    },
    {
      label: 'Danh sách chờ',
      data: [1, 10, 20, 1, 7, 8, 9],
      backgroundColor: '#FAEAB1',
    },
  ],
}
const data2 = {
  labels: ['Tích cực', 'Tiêu cực'],
  datasets: [
    {
      label: 'Đánh giá khóa học',
      data: [30, 5],
      backgroundColor: ['#FFBF00', '#65647C'],
      borderColor: ['#FFBF00', '#65647C'],
      borderWidth: 1,
    },
  ],
}
const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
]

const rows = [
  {
    id: 1,
    lastName: 'Sơn',
    firstName: 'Nguyễn Xuân',
    email: 'nguyennhuquynh@gmail.com',
    age: 35,
  },
  {
    id: 2,
    lastName: 'Kiên',
    firstName: 'Nguyễn Trung',
    email: 'nguyennhuquynh@gmail.com',
    age: 24,
  },
  {
    id: 3,
    lastName: 'Hằng',
    firstName: 'Trần Thị',
    email: 'nguyennhuquynh@gmail.com',
    age: 18,
  },
  {
    id: 4,
    lastName: 'Lâm',
    firstName: 'Nguyễn Vũ',
    email: 'nguyennhuquynh@gmail.com',
    age: 34,
  },
  {
    id: 5,
    lastName: 'Quỳnh',
    firstName: 'Hoàng Như',
    email: 'nguyennhuquynh@gmail.com',
    age: 14,
  },
  {
    id: 6,
    lastName: 'Như',
    firstName: 'Vân',
    email: 'nguyennhuquynh@gmail.com',
    age: 23,
  },
  {
    id: 7,
    lastName: 'Phương',
    firstName: 'Hoàng',
    email: 'nguyennhuquynh@gmail.com',
    age: 26,
  },
  {
    id: 8,
    lastName: 'Mikah Dang',
    firstName: 'Nguyễn Thị',
    email: 'nguyennhuquynh@gmail.com',
    age: 23,
  },
  {
    id: 9,
    lastName: 'Nhung',
    firstName: 'Nguyễn Thị ',
    email: 'nguyennhuquynh@gmail.com',
    age: 65,
  },
]

const Earnings = () => {
  return (
    <div className="-ml-4 pb-4 grow overflow-scroll rounded-tl-3xl rounded-bl-3xl border-l bg-white shadow-md">
      <div className="undefined">
        <div className="prose-product-lg">
          <div className="prose-product-sm flex h-full flex-col sm:prose-product-lg">
            <div className="z-10 flex h-20 items-center justify-between py-2 px-8">
              <div className="grow">
                <div className="mr-6 flex flex-auto justify-between">
                  <div className="prose-product-lg flex items-center">
                    <h2 className="mr-6 text-gray-900">Thu nhập</h2>
                  </div>
                </div>
              </div>
              <div className="relative inline-block text-left">
                <button
                  className="flex items-center justify-items-center rounded-full border border-gray-200 py-2 px-3 transition duration-200 ease-out hover:border-gray-300 hover:bg-gray-100 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <svg className="mt-1 h-6 w-6">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.00004 7.8335C9.9283 7.8335 10.8185 7.46475 11.4749 6.80837C12.1313 6.15199 12.5 5.26175 12.5 4.3335C12.5 3.40524 12.1313 2.515 11.4749 1.85862C10.8185 1.20225 9.9283 0.833496 9.00004 0.833496C8.07178 0.833496 7.18154 1.20225 6.52517 1.85862C5.86879 2.515 5.50004 3.40524 5.50004 4.3335C5.50004 5.26175 5.86879 6.15199 6.52517 6.80837C7.18154 7.46475 8.07178 7.8335 9.00004 7.8335ZM0.833374 18.3335C0.833374 17.261 1.04461 16.1991 1.45502 15.2082C1.86544 14.2174 2.46699 13.3171 3.22534 12.5588C3.98368 11.8004 4.88397 11.1989 5.87479 10.7885C6.86562 10.3781 7.92758 10.1668 9.00004 10.1668C10.0725 10.1668 11.1345 10.3781 12.1253 10.7885C13.1161 11.1989 14.0164 11.8004 14.7747 12.5588C15.5331 13.3171 16.1346 14.2174 16.5451 15.2082C16.9555 16.1991 17.1667 17.261 17.1667 18.3335H0.833374Z"
                      fill="#555555"
                    ></path>
                  </svg>
                  <svg className="h-6 w-6 border-gray-400">
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="border-t"></div>
            <div
              className="-mr-20 flex flex-col overflow-y-scroll pr-20"
              styles={{ height: 'calc(100vh - 80px)' }}
            >
              <div className="flex px-6">
                <div className="grow">
                  <div className="prose-product-lg">
                    <div
                      className="my-4 flex divide-x rounded-2xl border p-6"
                      styles={{ maxWidth: 'fit-content' }}
                    >
                      <div className="flex space-x-3 px-6 first:pl-0 last:pr-0">
                        <div className="m-auto">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M31 6.78L29 5H1V26.22L3 28H31V6.78Z"
                              fill="#779449"
                            ></path>
                            <path
                              d="M28 10.97V6H23.03C23.27 8.63 25.37 10.74 28 10.97V10.97Z"
                              fill="white"
                            ></path>
                            <path
                              d="M28 9.97V6H24.03C24.22 8.1 25.9 9.78 28 9.97Z"
                              fill="#A7D067"
                            ></path>
                            <path
                              d="M2 20.24V25.21H6.97C6.73 22.58 4.63 20.47 2 20.24Z"
                              fill="white"
                            ></path>
                            <path
                              d="M6.97 6H2V10.97C4.63 10.73 6.74 8.63 6.97 6V6Z"
                              fill="white"
                            ></path>
                            <path
                              d="M22.03 6H7.97C7.73 9.19 5.18 11.73 2 11.97V19.24C5.18 19.48 7.73 22.03 7.97 25.21H22.02C22.26 22.02 24.81 19.48 27.99 19.24V11.97C24.81 11.73 22.26 9.18 22.02 6H22.03Z"
                              fill="white"
                            ></path>
                            <path
                              d="M8.84 24.22C8.24 21.3 5.91 18.98 3 18.38V12.84C5.91 12.24 8.24 9.91 8.84 7H21.16C21.76 9.92 24.09 12.24 27 12.84V18.38C24.09 18.98 21.76 21.31 21.16 24.22H8.84Z"
                              fill="#A7D067"
                            ></path>
                            <path
                              d="M23.03 25.22H28V20.25C25.37 20.49 23.26 22.59 23.03 25.22Z"
                              fill="white"
                            ></path>
                            <path
                              d="M24.03 25.22H28V21.25C25.9 21.44 24.22 23.12 24.03 25.22Z"
                              fill="#A7D067"
                            ></path>
                            <path
                              d="M2 9.97V6H5.97C5.78 8.1 4.1 9.78 2 9.97V9.97Z"
                              fill="#A7D067"
                            ></path>
                            <path
                              d="M5.97 25.22H2V21.25C4.1 21.44 5.78 23.12 5.97 25.22V25.22Z"
                              fill="#A7D067"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-col space-y-2">
                          <h2>31.250.000 VND</h2>
                          <div className="p3">Toàn bộ thu nhập</div>
                        </div>
                      </div>
                      <div className="flex space-x-3 px-6 first:pl-0 last:pr-0">
                        <div className="m-auto">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M23.6499 4.99001L21.8699 3.21001C20.5999 1.86001 18.7999 1.01001 16.7899 1.01001C14.5499 1.01001 12.5499 2.08001 11.2799 3.73001C8.01987 3.76001 5.36987 6.42001 5.36987 9.69001C5.36987 11.38 6.07987 12.9 7.20987 13.99L9.05987 15.84C10.1399 16.96 11.6599 17.65 13.3299 17.65C14.5599 17.65 15.7099 17.27 16.6599 16.63C17.3299 16.85 18.0399 16.96 18.7899 16.96C22.6399 16.96 25.7699 13.83 25.7699 9.98001C25.7699 8.02001 24.9599 6.25001 23.6499 4.99001Z"
                              fill="#5B86A3"
                            ></path>
                            <path
                              d="M16.7998 13.97C20.1025 13.97 22.7798 11.2927 22.7798 7.99001C22.7798 4.68735 20.1025 2.01001 16.7998 2.01001C13.4972 2.01001 10.8198 4.68735 10.8198 7.99001C10.8198 11.2927 13.4972 13.97 16.7998 13.97Z"
                              fill="white"
                            ></path>
                            <path
                              d="M16.7998 12.97C19.5502 12.97 21.7798 10.7404 21.7798 7.99001C21.7798 5.23963 19.5502 3.01001 16.7998 3.01001C14.0494 3.01001 11.8198 5.23963 11.8198 7.99001C11.8198 10.7404 14.0494 12.97 16.7998 12.97Z"
                              fill="#7BB5DD"
                            ></path>
                            <path
                              d="M6.36987 9.69004C6.36987 12.43 8.59987 14.65 11.3299 14.65C12.1099 14.65 12.8399 14.45 13.4899 14.13C11.2999 12.95 9.80987 10.64 9.80987 7.98004C9.80987 6.83004 10.0899 5.75004 10.5899 4.79004C8.20987 5.15004 6.36987 7.19004 6.36987 9.68004V9.69004Z"
                              fill="#7BB5DD"
                            ></path>
                            <path
                              d="M1.95996 22.69V26.48H4.58996V23.4C4.58996 21.4 5.66996 19.97 7.86996 19.05C2.65996 19.57 1.95996 21.32 1.95996 22.69Z"
                              fill="#7BB5DD"
                            ></path>
                            <path
                              d="M29.56 22.1701L27.69 20.2801C25.93 18.6101 22.36 17.8101 16.79 17.8101C15.52 17.8101 14.37 17.8601 13.31 17.9401C12.69 17.9101 12.03 17.8901 11.34 17.8901C4.25996 17.8901 0.959961 19.4101 0.959961 22.6901V27.4801L2.95996 29.4801H5.07996L6.58996 30.9901H31V25.4001C31 24.0801 30.52 23.0001 29.56 22.1601V22.1701ZM4.57996 23.4101V26.4901H1.95996V22.7001C1.95996 21.3301 2.65996 19.5901 7.86996 19.0601C5.65996 19.9801 4.58996 21.4101 4.58996 23.4101H4.57996ZM28 28.0001H5.57996V23.4101C5.57996 21.4401 6.73996 18.8201 16.79 18.8201C26.84 18.8201 28 21.4401 28 23.4101V28.0001Z"
                              fill="#5B86A3"
                            ></path>
                            <path
                              d="M16.7901 18.8101C6.74008 18.8101 5.58008 21.4301 5.58008 23.4001V27.9901H28.0001V23.4001C28.0001 21.4301 26.8401 18.8101 16.7901 18.8101Z"
                              fill="white"
                            ></path>
                            <path
                              d="M16.7901 19.8101C6.58008 19.8101 6.58008 22.5101 6.58008 23.4001V26.9901H27.0001V23.4001C27.0001 22.5101 27.0001 19.8101 16.7901 19.8101Z"
                              fill="#7BB5DD"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-col space-y-2">
                          <h2>40</h2>
                          <div className="p3">Tổng số học viên</div>
                        </div>
                      </div>
                      <div className="flex space-x-3 px-6 first:pl-0 last:pr-0">
                        <div className="m-auto">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29.7899 2.42004H27.7399V2.29004L25.7299 0.290039H4.74994V2.42004H0.689941V6.68004C0.689941 6.83004 0.719941 6.98004 0.729941 7.13004H0.719941C0.719941 10.24 3.58994 12.72 6.05994 13.58C6.05994 13.58 8.08994 18.08 12.6699 20.04C12.4799 21.06 12.1699 22.06 11.7399 23H5.47994V29L7.47994 31H26.9999V25L24.9999 23H20.0299C19.8299 22.25 19.6999 21.49 19.6399 20.71C23.1599 19.88 25.9999 17.27 27.1599 13.89C29.7599 13.57 31.7799 11.37 31.7799 8.68004V4.42004L29.7799 2.42004H29.7899Z"
                              fill="#AD9056"
                            ></path>
                            <path
                              d="M24 24H6.47998V28H24V24Z"
                              fill="white"
                            ></path>
                            <path
                              d="M23 25H7.47998V27H23V25Z"
                              fill="#E8C073"
                            ></path>
                            <path
                              d="M5.75 8.51004C5.75 13.74 10.01 18 15.24 18C20.47 18 24.73 13.74 24.73 8.51004V1.29004H5.75V8.51004Z"
                              fill="white"
                            ></path>
                            <path
                              d="M15.24 17C10.56 17 6.75 13.19 6.75 8.51004V2.29004H23.73V8.51004C23.73 13.19 19.92 17 15.24 17Z"
                              fill="#E8C073"
                            ></path>
                            <path
                              d="M25.73 8.51004C25.73 9.31004 25.63 10.09 25.46 10.84C27.36 10.41 28.79 8.71004 28.79 6.68004V3.42004H25.74V8.51004H25.73Z"
                              fill="white"
                            ></path>
                            <path
                              d="M5.01994 10.84C4.84994 10.09 4.74994 9.31004 4.74994 8.51004V3.42004H1.68994V6.68004C1.68994 8.71004 3.10994 10.41 5.01994 10.84Z"
                              fill="white"
                            ></path>
                            <path
                              d="M13.82 18.9C13.7 20.32 13.35 21.69 12.79 23H17.68C17.13 21.69 16.77 20.31 16.65 18.9C16.19 18.96 15.71 19 15.23 19C14.75 19 14.28 18.96 13.81 18.9H13.82Z"
                              fill="#E8C073"
                            ></path>
                          </svg>
                        </div>
                        <div className="flex-col space-y-2">
                          <h2>2</h2>
                          <div className="p3">Tổng số lớp học</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ flex: 3 }}>
                      <Bar options={options} data={data} />
                    </Box>
                    <Box sx={{ flex: 1, ml: 2 }}>
                      <Pie data={data2} />
                    </Box>
                  </Stack>
                  <div style={{ height: 400, marginTop: '1.5rem' }}>
                    <Typography variant="h5" component="div">
                      Thông tin học viên đăng ký mới nhất
                    </Typography>
                    <DataGrid
                      sx={{
                        my: 2,
                      }}
                      rows={rows}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      autoHeight
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earnings
