import { Typography, Stack } from '@mui/material'
import React from 'react'
import CourseItem from './courseItem'
const imgJS =
  'https://techvccloud.mediacdn.vn/2018/11/23/js-15429579443112042672363-crop-1542957949936317424252.png'
const imgHTML =
  'https://letdiv.com/wp-content/uploads/2021/06/html-css-course.jpg'
const imgCPLUS =
  'https://ghouse.com.vn/wp-content/uploads/2019/07/ghouse-huongdan-c.jpg'

const TabCourse = () => {
  return (
    <React.Fragment>
      <Stack>
        <Typography sx={{ fontSize: '24px', fontWeight: 'bold', m: 0 }}>
          Khóa học đang theo
        </Typography>
        <CourseItem
          img={imgJS}
          NumberOfPati={2}
          total={10}
          NumberOfoff={1}
          courseName="Khóa học javascript cho người mới bắt đầu"
          teacherName={'Hoàn Vũ'}
          startDate="18-01-2022"
          endDate="02-02-2022"
        />
      </Stack>
      <Stack sx={{ mt: 6, width: '100%' }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 'bold', m: 0 }}>
          Khóa học đã xong
        </Typography>
        <CourseItem
          img={imgHTML}
          NumberOfPati={5}
          total={6}
          NumberOfoff={1}
          courseName="Khóa học HTML&CSS nâng cao"
          teacherName={'Hoàn Vũ'}
          startDate="02-01-2022"
          endDate="12-02-2022"
        />
        <CourseItem
          img={imgCPLUS}
          NumberOfPati={6}
          total={6}
          NumberOfoff={0}
          courseName="Khóa học c++ cơ bản"
          teacherName={'Hoàn Vũ'}
          startDate="18-01-2022"
          endDate="02-02-2022"
        />
      </Stack>
    </React.Fragment>
  )
}

export default TabCourse
