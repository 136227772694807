import * as yup from 'yup'
const Config = {
  numberOfFullname: 50,
  numberOfCoursename: 120,
}
const validationSchema = yup.object({
  email: yup
    .string('Nhập Email')
    .email('Vui lòng nhập đúng định dạng email')
    .required('Vui lòng nhập email'),
  coursename: yup
    .string('Nhập tên khóa học')
    .required('Vui lòng nhập tên khóa học')
    .max(
      Config.numberOfCoursename,
      `Vui lòng nhập lớn hơn ${Config.numberOfCoursename} ký tự`,
    )
    .min(3, `Vui lòng nhập lớn hơn 3 ký tự`),
  fullname: yup
    .string('Nhập họ tên')
    .required('Vui lòng nhập họ tên')
    .min(3, `Vui lòng nhập lớn hơn 3 ký tự`)
    .max(
      Config.numberOfCoursename,
      `Vui lòng nhập lớn hơn ${Config.numberOfCoursename} ký tự`,
    ),
})

export { Config, validationSchema }
