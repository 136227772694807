import React from 'react'
import {
  Box,
  Button,
  Modal as ModalShow,
  Typography,
  Stack,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import '../../static/css/10.83141e29.chunk.css'
import '../../static/css/extention.css'
import '../../static/css/main.c5644251.chunk.css'
import '../../static/css/modal.css'
import Modal from './components/modal'
import Body from './components/body'

const Home = () => {
  const [acitiveModal, setAcitiveModal] = React.useState(false)
  const [currentID, setCurrentId] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const titleRef = React.useRef()
  const courseRef = React.useRef()
  const contactRef = React.useRef()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '15px',
    boxShadow: 24,
    p: 4,
  }
  return (
    <div className="section full-height bg-white" style={{ padding: 0 }}>
      <input
        className="modal-btn"
        type="checkbox"
        id="modal-btn"
        name="modal-btn"
        style={{ display: 'none' }}
      />
      {currentID && (
        <Modal
          modal={[currentID, setCurrentId]}
          context={[acitiveModal, setAcitiveModal]}
          hideContext={{ open: open2, setOpen: setOpen2 }}
        />
      )}
      <div className="Toastify"></div>
      <Body
        context={[acitiveModal, setAcitiveModal, currentID, setCurrentId]}
        refS={{ titleRef, courseRef, contactRef }}
      />
      <ModalShow
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Thông báo
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Tham gia khóa học thành công
          </Typography>
          <Stack direction="row" sx={{ float: 'right' }} spacing={1} mt={5}>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              variant="contained"
              sx={{
                color: 'white',
                backgroundColor: '#f1880e ',
                ':hover': {
                  backgroundColor: '#f1880e ',
                },
              }}
            >
              OK
            </Button>
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              sx={{
                backgroundColor: '#eeeeee ',
                color: 'black',
                ':hover': {
                  backgroundColor: '#eeeeee ',
                },
              }}
            >
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ModalShow>
      <ModalShow
        open={open2}
        onClose={() => setOpen2(!open2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Thông báo
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Bạn có chắc muốn tham gia khóa học?
          </Typography>
          <Stack direction="row" sx={{ float: 'right' }} spacing={1} mt={5}>
            <LoadingButton
              sx={{
                color: 'white',
                backgroundColor: '#f1880e ',
                ':hover': {
                  backgroundColor: '#f1880e ',
                },
              }}
              onClick={() => {
                setLoading(true)
                setTimeout(() => {
                  setOpen2(false)
                  setOpen(true)
                  setLoading(false)
                }, 3000)
              }}
              loading={loading}
              variant="outlined"
            >
              Submit
            </LoadingButton>
            <Button
              onClick={() => setOpen2(false)}
              variant="contained"
              sx={{
                backgroundColor: '#eeeeee ',
                color: 'black',
                ':hover': {
                  backgroundColor: '#eeeeee ',
                },
              }}
            >
              CANCEL
            </Button>
          </Stack>
        </Box>
      </ModalShow>
    </div>
  )
}

export default Home
