import React from 'react'

const Settings = () => {
  return (
    <div className="-ml-4 grow overflow-hidden rounded-tl-3xl rounded-bl-3xl border-l bg-white shadow-md">
      <div className="undefined">
        <div className="prose-product-sm flex h-full flex-col sm:prose-product-lg">
          <div className="z-10 flex h-20 items-center justify-between py-2 px-8">
            <div className="grow">
              <div className="mr-6 flex flex-auto justify-between">
                <div className="prose-product-lg flex items-center">
                  <h2 className="mr-6 text-gray-900">Settings</h2>
                </div>
                <div className="flex items-center">
                  <div className="prose-product-sm sm:prose-product-lg">
                    <button
                      data-cy=""
                      className="justify-center items-center flex shadow-sm hover:shadow-md disabled:shadow-none ease-out duration-200 font-medium disabled:cursor-default h-10 px-4 b2 rounded-lg bg-gray-900 text-white hover:bg-black disabled:bg-gray-500"
                      type="button"
                    >
                      <svg
                        className="mr-2"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.5 8H13.5"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M8 2.5V13.5"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                      New cohort
                    </button>
                  </div>
                  <div className="mx-3 h-full border-l"></div>
                </div>
              </div>
            </div>
            <div className="'relative' 'inline-block' text-left">
              <button
                className="flex items-center justify-items-center rounded-full border border-gray-200 py-2 px-3 transition duration-200 ease-out hover:border-gray-300 hover:bg-gray-100 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <svg className="mt-1 h-6 w-6">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.00004 7.8335C9.9283 7.8335 10.8185 7.46475 11.4749 6.80837C12.1313 6.15199 12.5 5.26175 12.5 4.3335C12.5 3.40524 12.1313 2.515 11.4749 1.85862C10.8185 1.20225 9.9283 0.833496 9.00004 0.833496C8.07178 0.833496 7.18154 1.20225 6.52517 1.85862C5.86879 2.515 5.50004 3.40524 5.50004 4.3335C5.50004 5.26175 5.86879 6.15199 6.52517 6.80837C7.18154 7.46475 8.07178 7.8335 9.00004 7.8335ZM0.833374 18.3335C0.833374 17.261 1.04461 16.1991 1.45502 15.2082C1.86544 14.2174 2.46699 13.3171 3.22534 12.5588C3.98368 11.8004 4.88397 11.1989 5.87479 10.7885C6.86562 10.3781 7.92758 10.1668 9.00004 10.1668C10.0725 10.1668 11.1345 10.3781 12.1253 10.7885C13.1161 11.1989 14.0164 11.8004 14.7747 12.5588C15.5331 13.3171 16.1346 14.2174 16.5451 15.2082C16.9555 16.1991 17.1667 17.261 17.1667 18.3335H0.833374Z"
                    fill="#555555"
                  ></path>
                </svg>
                <svg className="h-6 w-6 border-gray-400">
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="border-t"></div>
          <div
            className="-mr-20 flex flex-col overflow-y-scroll pr-20"
            styles={{ height: 'calc(100vh - 80px)' }}
          >
            <div className="flex px-6">
              <div className="grow">
                <div className="prose-product-sm ml-12 flex max-w-screen-md sm:prose-product-lg">
                  <div>
                    <div className="sticky top-0 mr-12 h-auto w-48 self-start bg-white pt-8 text-gray-700">
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Cài đặt chung</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Tên và URL khóa học
                            </div>
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Cohort info
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Thanh toán</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Stripe
                            </div>
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Pricing
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Ứng dụng</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Applications
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Quảng bá</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Images
                            </div>
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Email
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Cộng đồng</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Course community
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Tích hợp</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Webhooks
                            </div>
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              Zoom
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">School admins</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700">
                              School admins
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex min-h-10 w-full rounded-lg p-3 pb-0 text-left transition duration-200 cursor-pointer hover:bg-gray-100">
                        <div>
                          <svg
                            className="text-gray-500 -rotate-90 transition-transform duration-200 ease-out"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 6L8 11L3 6"
                              stroke="currentColor"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div className="w-full">
                          <h5 className="ml-3 text-gray-700">Nhân viên</h5>
                          <div className="hidden">
                            <div className="p3 mr-1 flex h-8 cursor-pointer items-center truncate rounded-lg pl-3 hover:bg-gray-100 text-gray-700"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-160 settingsSectionOrSubsection">
                    <div
                      className="pt-8 settingsSectionOrSubsection"
                      id="general"
                    >
                      <h2 className="mb-4 cursor-pointer text-primary-dark">
                        General
                      </h2>
                      <div>
                        <div
                          className="mb-4 rounded-xl border border-gray-200 settingsSectionOrSubsection"
                          id="names-and-urls"
                        >
                          <div className="flex min-h-12 justify-between rounded-t-xl border-b border-gray-200 bg-gray-100 px-8 py-3 hover:bg-gray-100">
                            <div className="flex items-center justify-between">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="mr-3 cursor-pointer text-gray-800">
                                    Tên và URL khóa học
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center"></div>
                          </div>
                          <div className="p-8">
                            <div>
                              <div className="mb-6">
                                <div className="prose-product-lg">
                                  <h5 className="flex justify-between text-gray-800">
                                    <label className="mb-3">Tên lớp</label>
                                  </h5>
                                </div>
                                <div className="prose-product-sm flex items-center sm:prose-product-lg">
                                  <input
                                    className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200 undefined"
                                    type="text"
                                    maxlength="40"
                                    value="Mikah Dang"
                                  />
                                </div>
                                <div className="mt-1 flex justify-between">
                                  <div className="prose-product-sm sm:prose-product-lg">
                                    <p className="p4 text-gray-600">
                                      Đây là ngôi nhà cho (các) khóa học của
                                      bạn. Nếu bạn không chắc chắn, chúng tôi
                                      khuyên bạn nên sử dụng tên của mình.
                                    </p>
                                  </div>
                                  <div className="ml-3 justify-self-end">
                                    <p className="text-right text-xs text-gray-700">
                                      17/40
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-6">
                                <div className="prose-product-lg">
                                  <h5 className="flex justify-between text-gray-800">
                                    <label className="mb-3">Tên khóa học</label>
                                  </h5>
                                </div>
                                <div className="prose-product-sm flex items-center sm:prose-product-lg">
                                  <input
                                    className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-4 w-full border rounded-lg border-gray-300 bg-white shadow-sm hover:border-gray-400 hover:shadow-md transition ease-out duration-200 undefined"
                                    type="text"
                                    maxlength="80"
                                    value="Lộ trình để trở thành web3 developer"
                                  />
                                </div>
                                <div className="mt-1 flex justify-between">
                                  <div className="prose-product-sm sm:prose-product-lg">
                                    <p className="p4 text-gray-600">
                                      Điều này hiển thị trên trang đích của bạn
                                      và trong cổng thông tin sinh viên.
                                    </p>
                                  </div>
                                  <div className="ml-3 justify-self-end">
                                    <p className="text-right text-xs text-gray-700">
                                      23/80
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div className="prose-product-sm flex justify-between sm:prose-product-lg">
                                    <div className="prose-product-lg">
                                      <h5 className="flex justify-between text-gray-800">
                                        <label className="mb-3">
                                          URL Khóa học
                                        </label>
                                      </h5>
                                    </div>
                                    <div className="b2 text-primary cursor-pointer">
                                      Edit
                                    </div>
                                  </div>
                                  <div className="mb-3 prose-product-sm flex items-center sm:prose-product-lg">
                                    <div className="rounded-l-lg border border-r-0 border-gray-300 bg-gray-100 py-3.5 px-3 text-gray-600 shadow-sm flex-shrink">
                                      maven.com/
                                    </div>
                                    <input
                                      className="placeholder-shown:italic placeholder-gray-600 p2 py-3.5 px-3 w-full border rounded-r-lg border-gray-300 bg-gray-200 text-gray-600 flex-grpw"
                                      style={{
                                        padding: '26px 15px',
                                        marginBottom: '0px',
                                      }}
                                      type="text"
                                      disabled=""
                                      maxlength="121"
                                      value="Mikah-Dang/placeholder-yoympl"
                                    />
                                  </div>
                                  <div className="mt-1 flex justify-between">
                                    <div className="prose-product-sm sm:prose-product-lg">
                                      <p className="p4 text-gray-600">
                                        Hãy cẩn thận! Việc cập nhật URL khóa học
                                        của bạn sẽ phá vỡ mọi liên kết hiện có
                                        mà bạn đã chia sẻ.
                                      </p>
                                    </div>
                                    <div className="ml-3 justify-self-end">
                                      <p className="text-right text-xs text-gray-700">
                                        36/121
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
