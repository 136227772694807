import { Card, Typography, Stack, Box } from '@mui/material'
import React from 'react'

const CourseItem = ({
  img,
  courseName,
  teacherName,
  total,
  NumberOfoff,
  NumberOfPati,
  startDate,
  endDate,
}) => {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          minHeight: '130px',
        }}
      >
        <img
          style={{
            height: 'auto',
            width: '170px',
          }}
          src={img}
        />
        <Stack
          sx={{
            p: 2,
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontStyle: 'italic',
            }}
          >
            {courseName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            {teacherName}
          </Typography>
        </Stack>
        <Stack
          sx={{
            p: 2,
            flex: 1,
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Thông tin khóa học
          </Typography>
          <Typography>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              - Tổng số:{' '}
            </span>
            {total} buổi
          </Typography>
          <Typography>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              - Đã tham gia:{' '}
            </span>
            {NumberOfPati} buổi
          </Typography>
          <Typography>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              - Đã nghỉ:{' '}
            </span>
            {NumberOfoff} buổi
          </Typography>
        </Stack>
        <Stack
          sx={{
            p: 2,
            flex: 1,
          }}
        >
          <Typography>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              - Ngày bắt đầu:{' '}
            </span>
            {startDate}
          </Typography>
          <Typography>
            <span
              style={{
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              - Ngày kết thúc:{' '}
            </span>
            {endDate}
          </Typography>
        </Stack>
      </Card>
    </Box>
  )
}

export default CourseItem
