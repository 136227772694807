import React from 'react'
const img1 =
  'https://res.cloudinary.com/di4m7zawx/image/upload/v1670991441/BOOST/1_dw8gsi.png'

const Teacher_Info = () => {
  return (
    <div className="container">
      <div id="instructor" className="style-layout-who-course-for">
        <div className="row">
          <div className="col-6 col-md-5 mb-4 mb-md-5 pb-3 mr-0 mr-md-5 mb-md-0">
            <div className="d-flex justify-content-center align-items-center">
              <div className="z-index-1 text-center box-shadow-course">
                <img
                  src={img1}
                  alt=""
                  style={{
                    height: '95%',
                    width: '95%',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    backgroundColor: 'rgb(217, 224, 240)',
                    marginTop: '8px',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="pt-5 pt-md-0">
              <p className="font-svn-medium font-size-36 font-size-mb-24 font-weight-700 color-4754e2 mb-0">
                Giảng viên
              </p>
              <p className="font-svn-medium font-size-56 font-size-mb-18 font-weight-700">
                Hoàn Vũ
              </p>
              <div className="d-none d-md-block">
                <p className="font-size-18 font-size-mb-16 font-weight-normal mb-4">
                  Anh Hoàn Vũ đã có hơn 10 năm kinh nghiệm lập trình chuyên
                  nghiệp. Anh cũng là Co-founder &amp, CTO của CHECKIT và
                  Founder của Blockchain Hardcore Series.
                </p>
              </div>
              <div className="d-flex mb-5">
                <div className="style-border-link-instrctor bg-4754e2 d-flex justify-content-center align-items-center mr-3">
                  <a
                    href="#"
                    className="d-flex justify-content-center align-items-center pe-none"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      color="#fff"
                      height="16px"
                      width="16px"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: 'rgb(255, 255, 255) ' }}
                    >
                      <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                    </svg>
                  </a>
                </div>
                <div className="style-border-link-instrctor bg-4754e2 d-flex justify-content-center align-items-center mr-3">
                  <a
                    href="https://www.linkedin.com/in/khanhtong"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      color="#fff"
                      height="16px"
                      width="16px"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: 'rgb(255, 255, 255) ' }}
                    >
                      <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                    </svg>
                  </a>
                </div>
                <div className="style-border-link-instrctor bg-4754e2 d-flex justify-content-center align-items-center">
                  <a
                    href="#"
                    className="d-flex justify-content-center align-items-center pe-none"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      color="#fff"
                      height="16px"
                      width="16px"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: 'rgb(255, 255, 255) ' }}
                    >
                      <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-md-none">
            <p className="font-size-18 font-size-mb-16 font-weight-normal">
              Anh Hoàn Vũ đã có hơn 10 năm kinh nghiệm lập trình chuyên nghiệp.
              Anh cũng là Co-founder &amp, CTO của CHECKIT và Founder của
              Blockchain Hardcore Series.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Teacher_Info
