import React from 'react'

export const GlobalContext = React.createContext()
const Context = ({ children }) => {
  const [courses, setCourses] = React.useState([])
  return (
    <GlobalContext.Provider
      value={{
        courses,
        setCourses,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default Context
