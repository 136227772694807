import React from 'react'
import '../../static/css/10.83141e29.chunk.css'
import '../../static/css/extention.css'
import '../../static/css/main.c5644251.chunk.css'
import '../../static/css/modal.css'
import { COLOR } from '../../constants/color'
const Footer = ({ contactRef }) => {
  return (
    <div id="lien-he" ref={contactRef}>
      <div className="bg-black">
        <div className="container pl-0 pr-0 p-3">
          <div className="mt-5 mb-3 mb-md-5 row">
            <div class="col-sm-12 col-md-4">
              <p class="font-svn font-weight-400 font-size-24 text-white mb-3">
                Đăng ký nhận tin tức mới
              </p>
              <form class="bg-transparent">
                <div class="row ">
                  <div class="col-10 col-md-8 pr-3 ">
                    <div class="">
                      <input
                        id="email"
                        type="email"
                        class="form-control style-input-login style-input-mail bg-transparent font-svn font-size-14 line-height-16 color-494949"
                        placeholder="Nhập địa chỉ email"
                        aria-label=""
                        value=""
                      />
                    </div>
                  </div>
                  <div
                    class="col-2 col-md-2 mb-3"
                    style={{ backgroundColor: COLOR.bg_MAIN }}
                  >
                    <button
                      class="btn btn-hop-tac mr-0 w-100 p-0"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="#fff"
                      >
                        <path d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
              <div class="d-flex">
                <div class="d-flex">
                  <p class="font-svn-medium font-size-14 font-weight-400 text-white mb-0">
                    Liên hệ với chúng tôi:
                  </p>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <a
                    href="mailto:info@cohooz.com"
                    class="font-svn-medium font-size-14 font-weight-400 color-4754e2 mb-0 ml-2"
                  >
                    info@boost.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mb-3 mb-md-0 col-12 col-md-3">
              <div className="d-flex justify-content-center align-items-center h-100">
                <p className="mb-0 style-text-footer-black font-size-mb-14 w-100">
                  <span>©</span>
                  Copyright 2022 Boost
                </p>
              </div>
            </div>
            <div className="d-block d-md-none mb-3 col-12 col-md-3">
              <div className="d-flex">
                <div className="mr-3 mr-lg-5 cursor-pointer">
                  <a href="https://www.facebook.com/cohooz" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff"
                    >
                      <path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z"></path>
                    </svg>
                  </a>
                </div>
                <div className="mr-3 mr-lg-5 cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#fff"
                  >
                    <path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path>
                  </svg>
                </div>
                <div className="mr-3 mr-lg-5 cursor-pointer">
                  <a
                    href="https://www.linkedin.com/company/cohooz/"
                    target="_blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff"
                    >
                      <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path>
                    </svg>
                  </a>
                </div>
                <div className="mr-3 mr-lg-5 cursor-pointer">
                  <a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#fff"
                    >
                      <path d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
