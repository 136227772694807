import React from 'react'
import { useNavigate } from 'react-router-dom'
const Login = () => {
  const navigate = useNavigate()
  function Login() {
    const email = document.querySelector('#txtemail').value
    const pass = document.querySelector('#txtpassword').value
    if (email && pass) {
      localStorage.setItem('users', JSON.stringify('xuanthanh'))
      navigate('/')
    }
  }
  return (
    <div className="container p-8">
      <div className="row">
        <div className="bg-left-modal-sign d-none d-md-block col-md-6">
          <div className="style-layout-modal-sign">
            <div className="mb-5"></div>
            <div className="mb-5">
              <p className="font-svn-black font-size-36 font-weight-900 text-white mb-0">
                Học
                <span className="color-6A99FF"> trực tuyến </span>
              </p>
              <p className="font-svn-black font-size-36 font-weight-900 text-white">
                hiệu quả như học
                <span className="color-4754e2"> trực tiếp</span>
              </p>
            </div>
            <div className="pb-5">
              <p className="font-svn font-size-14 font-weight-normal text-white pb-5 mb-5">
                Boost là một nền tảng giáo dục trực tuyến kiểu mới. Chúng tôi
                cung cấp những khoá học từ những chuyên gia hàng đầu cùng trải
                nghiệm học có tương tác khiến việc học trở nên dễ dàng và hiệu
                quả hơn.
              </p>
            </div>
            <p className="font-svn font-size-14 font-weight-normal text-white mb-2">
              Bạn đã có tài khoản chưa?
            </p>
            <p className="font-svn font-size-14 font-weight-normal text-white">
              Đăng ký tài khoản ngay
              <span className="font-svn font-size-16 font-weight-700 font-style-italic text-white cursor-pointer">
                Tại đây!
              </span>
            </p>
          </div>
        </div>
        <div className="bg-white bg-right-modal-sign col-md-6 col-sm-12">
          <div className="style-layout-modal-sign position-relative">
            <form method="post">
              <div className="form-row position-relative">
                <div className="form-group col-md-12 col-sm-12 mb-32">
                  <div className="font-svn font-size-16 font-weight-700 mb-3">
                    Email
                  </div>
                  <input
                    name="email"
                    type="email"
                    className="style-input-login"
                    id="txtemail"
                    value="hd13112011@gmail.com"
                  />
                  <div
                    className="text-error-input mt-2 ml-3"
                    id="lberroremail"
                  ></div>
                </div>
                <div className="form-group col-md-12 col-sm-12 mb-32">
                  <div className="font-svn font-size-16 font-weight-700 mb-3">
                    Mật khẩu
                  </div>
                  <div className="password-input">
                    <input
                      name="password"
                      type="password"
                      className="style-input-password password-char"
                      id="txtpassword"
                      value="123456"
                    />
                    <a
                      className="password-show-hide disabled"
                      style={{ position: 'absolute', top: '22px' }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 16 16"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"></path>
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"></path>
                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"></path>
                      </svg>
                    </a>
                  </div>
                  <div
                    className="text-error-input mt-2 ml-3"
                    id="lberrorpassword"
                  ></div>
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="mb-3 ml-2">
                        <div className="style-text-SVN-Regular font-size-14 font-weight-normal style-luu-thong-tin-form-sign-up form-check">
                          <input
                            type="checkbox"
                            id="formBasicCheckbox"
                            className="form-check-input"
                          />
                          <label
                            title=""
                            for="formBasicCheckbox"
                            className="form-check-label"
                          >
                            Lưu thông tin
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <u className="style-text-SVN-Regular font-size-12 font-weight-normal cursor-pointer">
                        Quên mật khẩu?
                      </u>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  onClick={Login}
                  className="btn w-100 btn-hop-tac w-100"
                  style={{ backgroundColor: '#f1880e' }}
                >
                  ĐĂNG NHẬP
                </button>
              </div>
            </form>
            <div className="style-border-bottom-form-sign position-relative mb-5">
              <p className="style-absolute-border-bottom-form-sign style-text-SVN-Regular font-size-12 font-weight-400">
                hoặc
              </p>
            </div>
            <div className="mb-4">
              <div>
                <button
                  type="button"
                  className="btn w-100 btn-login-google d-flex justify-content-center align-items-center font-svn font-size-14 font-weight-600 btn btn-primary"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 48 48"
                    enable-background="new 0 0 48 48"
                    className="mr-2"
                    height="16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                  Đăng nhập với Google
                </button>
              </div>
            </div>
            <div className="pt-3 d-block d-md-none">
              <p className="font-svn font-size-14 font-weight-normal text-center mb-2">
                Bạn đã có tài khoản chưa?
              </p>
              <p className="font-svn font-size-14 font-weight-normal text-center">
                Đăng ký tài khoản ngay
                <span className="font-svn font-size-16 font-weight-700 font-style-italic color-4754e2">
                  Tại đây!
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
