import React from 'react'
import { Link } from 'react-router-dom'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import EmailIcon from '@mui/icons-material/Email'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import SettingsIcon from '@mui/icons-material/Settings'
const menu = [
  {
    id: 2,
    name: 'Dashboard',
    to: '/admin/earnings',
    icon: AttachMoneyIcon,
  },
  {
    id: 1,
    name: 'Học viên',
    to: '/admin/students',
    icon: PeopleOutlineIcon,
  },
  {
    id: 3,
    name: 'Email',
    to: '/admin/email',
    icon: EmailIcon,
  },
  {
    id: 4,
    name: 'Sự kiện',
    to: '/admin/events',
    icon: CalendarMonthIcon,
  },
  {
    id: 5,
    name: 'Khảo sát',
    to: '/admin/surveys',
    icon: AnalyticsIcon,
  },
  {
    id: 6,
    name: 'Cài đặt',
    to: '/admin/settings',
    icon: SettingsIcon,
  },
]
const Header = () => {
  return (
    <header className="flex h-full min-h-screen shrink-0 flex-col items-center bg-gray-900 pr-4">
      <nav>
        <ul className="py-8">
          <Link to="/admin/set-up">
            <figure className="cursor-pointer duration-200 ease-out hover:bg-gray-800 mb-0.5 px-6 py-4 text-gray-500 transition bg-gray-800 shadow-sm text-white">
              <div className="mb-2 flex items-center justify-between">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M11.54.875a1.253 1.253 0 0 1 1.574.006c1.03.836 3.262 2.92 4.494 6.122.429 1.116.735 2.363.828 3.734l3.329 3.995a1.254 1.254 0 0 1 .26 1.075l-1.32 5.942a1.254 1.254 0 0 1-2.008.707l-3.092-2.474H9.05l-3.093 2.474a1.254 1.254 0 0 1-2.008-.707l-1.32-5.942a1.254 1.254 0 0 1 .26-1.075l3.264-3.916c.084-1.405.396-2.68.838-3.82C8.236 3.792 10.5 1.709 11.54.876Zm-5.4 11.207-2.635 3.162-.307-.256.307.256a.454.454 0 0 0-.094.39l1.32 5.941a.454.454 0 0 0 .728.256l2.935-2.348c-1.522-2.73-2.169-5.205-2.253-7.401Zm10.119 7.4 2.938 2.35a.454.454 0 0 0 .727-.257l1.32-5.941a.455.455 0 0 0-.094-.39l-2.69-3.229c-.067 2.212-.695 4.71-2.201 7.466Zm-3.931-9.084a1.068 1.068 0 1 0 0-2.136 1.068 1.068 0 0 0 0 2.136ZM10.218 20.7c0-.11.18-.2.4-.2h3.418c.221 0 .4.09.4.2 0 .11-.179.2-.4.2H10.62c-.221 0-.4-.09-.4-.2Zm.4.5c-.22 0-.4.09-.4.2 0 .11.18.2.4.2h3.418c.221 0 .4-.09.4-.2 0-.11-.179-.2-.4-.2H10.62Zm-.4.9c0-.11.18-.2.4-.2h3.418c.221 0 .4.09.4.2 0 .11-.179.2-.4.2H10.62c-.221 0-.4-.09-.4-.2Zm0 .7c0-.11.18-.2.4-.2h3.418c.221 0 .4.09.4.2 0 .11-.179.2-.4.2H10.62c-.221 0-.4-.09-.4-.2Z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <figcaption className="mx-2 rounded-xl text-sm font-medium transition duration-200 ease-out text-gray-500">
                  Thiết lập
                </figcaption>
                <figcaption className="text-sm text-gray-500">22%</figcaption>
              </div>
              <figure className="flex flex-1 items-center">
                <div className="border-box h-3 w-full rounded-full border border-white/20 bg-white/10">
                  <div
                    className="h-full rounded-full"
                    style={{ width: '22%', background: 'rgb(0, 158, 61)' }}
                  ></div>
                </div>
              </figure>
            </figure>
          </Link>
          {menu.map((item) => (
            <Link
              to={item.to}
              className="mb-0.5 flex list-none items-center px-6 py-2.5 text-sm transition duration-200 ease-out hover:bg-gray-800 cursor-pointer text-gray-500 px-0 py-0 false border-l-transparent"
            >
              <div className="block flex flex-1 items-center px-6 py-2.5">
                <div className="mr-2">{<item.icon />}</div>
                {item.name}
              </div>
            </Link>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
